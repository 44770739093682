import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { db, auth } from '../config/firebase';
import { doc, setDoc, updateDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; // Import authentication state listener
import Swal from 'sweetalert2';


// Sample Aralin data
const aralinData = [
    {   //ARALIN 111111111
        title: "Aralin 1: Kahulugan, Kasaysayan at Dahilan ng Globalisasyon",
        activities: {
            "Concept Mapping": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon ",
                instructions: 'Ilagay ang "Globalisasyon" sa gitna bilang sentral na ideya. Mula dito, magdagdag ng pangunahing konsepto (ekonomiya, kultura, teknolohiya, komunikasyon, pulitika). Gumamit ng mga linya para ipakita ang ugnayan ng mga konsepto. Idagdag ang mga halimbawa sa bawat konsepto. Gawing malinaw at organisado ang pagkakalahad ng ideya.',
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Kalinawan ng mga Konsepto",
                        column2: "Lahat ng mga konsepto ay malinaw, tama, at naaayon sa globalisasyon; mahusay na pagkaka-organisa at madaling maunawaan",
                        column3: "Lahat ng pangunahing konsepto ay malinaw subalit may ilang detalye na maaaring gawing mas tiyak o malinaw.",
                        column4: "May ilang mga konseptong hindi malinaw o hindi naunawaan na maaaring magdulot ng kalituhan.",
                        column5: "Hindi malinaw ang konseptong ipinakita."
                    },
                    {
                        column1: "Ugnayan ng mga Ideya",
                        column2: "Napakahusay at lohikal ang koneksyon ng mga konsepto; malinaw na ipinapakita ang relasyon ng bawat aspeto ng globalisasyon.",
                        column3: "Malinaw at sapat ang mga koneksyon, subalit may ilang bahagi na maaaring gawing mas lohikal.",
                        column4: "May mga koneksyon subalit hindi lahat ay malinaw o lohikal ang pagkakaayos.",
                        column5: "Hindi lohikal ang koneksyon sa pagitan ng mga konsepto at ugnayan ng mga ideya."
                    },
                    {
                        column1: "Pagkamalikhain at Presentasyon",
                        column2: "Napaka maliikhain, at epektibong gumamit ng kulay, simbolo, at visual aids upang ipakita ang mga ideya.",
                        column3: "Maayos at malikhain, subalit may ilang aspeto ng presentasyon na maaaring pagandahin pa (kulay, organisasyon).",
                        column4: "Ang presentasyon ay may kalinisan subalit kulang sa visual aids o creativity, kaya medyo mahirap maunawaan.",
                        column5: "Magulo at  walang paggamit ng visual aids o simbolo."
                    },
                    {
                        column1: "Mga Halimbawa",
                        column2: "Lahat ng konsepto ay suportado ng mga angkop at malinaw na halimbawa, nagpapalalim sa pag-unawa ng mga ideya.",
                        column3: "May sapat na halimbawa, subalit may ilang hindi angkop sa konseptong tinatalakay. ",
                        column4: "Ilan lamang sa mga konsepto ang may halimbawa, at ang mga ito ay hindi sapat na nagpapalawak ng pagkaunawa.",
                        column5: "May halimbawa subalit hindi naaayon ang mga ito sa mga konseptong tinatalakay."
                    },
                ],
            },
            "Group Discussion Reflection": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon ",
                instructions: " Sumulat ng maikling reflection paper (200-300 salita). Ipakita ang iyong sariling opinyon at natutunan mula sa talakayan. Sagutin ang mga sumusunod na tanong: \n 1. Ano ang pinakamahalagang natutunan mo mula sa talakayan? \n 2. Paano ito nakaapekto sa iyong pag-unawa sa globalisasyon? \n 3. Ano ang iyong kontribusyon sa talakayan?",
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Malalim na pagninilay",
                        column2: "Napakalalim at kritikal ang pagninilay",
                        column3: "May sapat na lalim ang pagninilay.",
                        column4: "May kaunting lalim sa pagninilay subalit hindi naaayon ang mga ito sa mga konseptong tinatalakay.",
                        column5: "Walang sapat na pagninilay."
                    },
                    {
                        column1: "Pag-uugnay sa aralin",
                        column2: "Mahusay na nailapat ang mga ideya sa aralin",
                        column3: "Sapat na nailapat sa aralin.",
                        column4: "Kaunti lamang ang kaugnayan sa aralin.",
                        column5: "Walang malinaw na pag-uugnay sa aralin."
                    },
                    {
                        column1: "Klaridad ng pagsasalaysay",
                        column2: "Lubos na malinaw at organisado ang pagsasalaysay",
                        column3: "Malinaw at sapat ang pagsasalaysay.",
                        column4: "Medyo magulo ang pagsasalaysay",
                        column5: "Hindi malinaw ang pagsasalaysay"
                    },
                    {
                        column1: "Sapat na bilang ng salita",
                        column2: "Ang bilang ng salita ay nasa loob ng itinakdang saklaw (200-300 salita).",
                        column3: "Ang bilang ng salita ay bahagyang nasa loob ng saklaw, may konting kakulangan o kalabisan (180-199 o 301-320 salita).",
                        column4: "Ang bilang ng salita ay masyadong kulang o labis (150-179 o 321-350 salita).",
                        column5: "Ang bilang ng salita ay lubos na malayo sa itinakdang saklaw (mas mababa sa 150 o higit sa 350 salita)."
                    },
                ],
            },
            "Timeline Creation": {
                objectives: "Mailahad ng mga mag-aaral ang mga mahahalagang pangyayari sa kasaysayan ng globalisasyon.",
                instructions: "Pumili ng 5-7 mahahalagang pangyayari sa kasaysayan ng globalisasyon. Isulat ang taon at maikling paliwanag ng bawat pangyayari. Ayusin ang mga ito sa tamang pagkakasunod sa isang timeline. Siguraduhing malinaw at maayos ang presentasyon ng iyong timelin.",
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Kawastuhan ng mga petsa",
                        column2: "Tamang-tama ang lahat ng mga petsa.",
                        column3: "May ilang maliit na pagkakamali sa petsa.",
                        column4: "Maraming pagkakamali sa petsa.",
                        column5: "Mali ang mga petsa."
                    },
                    {
                        column1: "Pagkilala sa mga pangyayari",
                        column2: "Mahusay na natukoy ang lahat ng mahahalagang pangyayari.",
                        column3: "Natukoy ang karamihan sa mahahalagang pangyayari.",
                        column4: "Ilang pangyayari lamang ang natukoy.",
                        column5: "Hindi natukoy ang mga mahahalagang pangyayari."
                    },
                    {
                        column1: "Pagsasaayos ng pangyayari",
                        column2: "Malinaw at lohikal ang pagkakasunod-sunod ng mga pangyayari.",
                        column3: "pagkakasunod-sunod ng mga pangyayari.",
                        column4: "Hindi gaanong lohikal ang pagkakaayos.",
                        column5: "Walang lohikal na pagkakasunod-sunod."
                    },
                    {
                        column1: "Presentasyon ng timeline",
                        column2: "Napakalinaw at kaaya-ayang tingnan.",
                        column3: "Malinaw at maayos.",
                        column4: "Medyo magulo ang pagkakapresenta.",
                        column5: "Magulo ang pagkakapresenta."
                    },
                ],
            },
            "Think-Pair-Share": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Think - Mag-isip nang tahimik tungkol sa mga dahilan ng globalisasyon sa loob ng 2 minuto. Isulat ang iyong mga ideya sa papel. Pair - Makipag-partner sa isang kaklase at ibahagi ang iyong mga iniisip. Mag-usap ng 5 minuto tungkol sa inyong mga ideya. Share - Ibahagi ang mga natutunan mula sa inyong pag-uusap sa buong klase. Siguraduhing makinig sa iyong partner at respetuhin ang kanyang opinyon. Tiyakin na malinaw ang iyong mga pahayag kapag nagbabahagi sa klase.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Pag-iisip",
                        column2: "Malalim at mayaman ang mga ideya.",
                        column3: "Sapat ang mga ideya subalit hindi ganap.",
                        column4: "Medyo mababaw ang mga ideya.",
                        column5: "Karamihan ay hindi makabuluhan."
                    },
                    {
                        column1: "Pakikipag-usap",
                        column2: "Aktibong nakinig at nagbahagi ng ideya.",
                        column3: "Nakinig subalit may kakulangan sa pakikipag-usap.",
                        column4: "May pagkukulang sa pakikinig.",
                        column5: "Walang pakikipag-usap o hindi nakikinig."
                    },
                    {
                        column1: "Pagsasalin ng Ideya",
                        column2: "Malinaw at maayos na naibahagi ang mga ideya sa klase.",
                        column3: "Naipahayag ang mga ideya subalit may mga hindi malinaw.",
                        column4: "May mga ideya na hindi naiintindihan.",
                        column5: "Halos walang naibahaging ideya."
                    },
                    {
                        column1: "Pakikilahok",
                        column2: "Aktibong nakilahok sa talakayan ng kapareha.",
                        column3: "Nakilahok subalit may kaunting kakulangan.",
                        column4: "Limitado ang pakikilahok sa talakayan.",
                        column5: "Walang aktibong pakikilahok sa talakayan."
                    },
                ],
            },
            "Jigsaw Activity: Globalization Themes": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Hatiin ang klase sa limang pangkat at bigyan ang bawat isa ng isang tema ng globalisasyon (Ekonomiko, Kultura, Pulitika, Teknolohiya, Kapaligiran). Pag-aralan ng bawat pangkat ang kanilang tema at maghanda ng maikling presentasyon na naglalaman ng kahulugan, mga halimbawa, at epekto ng globalisasyon sa kanilang tema. Pagkatapos, lilipat ang bawat miyembro sa bagong pangkat upang ibahagi ang kanilang kaalaman sa tema at matuto mula sa iba. Sa huli, bubuuin ng bawat pangkat ang kabuuang kaalaman at maghahanda ng presentasyon sa globalisasyon.",
                tableData: [
                    {
                        column1: "Nilalaman (4)",
                        column2: "Kumpleto at may lalim ang impormasyon tungkol sa bawat tema; may konkretong halimbawa at epekto",
                    },
                    {
                        column1: "Organisasyon ng Pahayag (3)",
                        column2: "Malinaw ang daloy ng paliwanag at bawat miyembro ay nakapagbahagi ng kaalaman tungkol sa kanilang tema.",
                    },
                    {
                        column1: "Pagtutulungan (2)",
                        column2: "Bawat miyembro ay aktibong nakilahok sa talakayan at tumulong sa pagbubuod at presentasyon.",
                    },
                    {
                        column1: "Kahusayan sa Pagsasalita (1)",
                        column2: "Malinaw at maayos ang pagsasalita ng bawat miyembro; may kumpiyansa at nakikipag-ugnayan sa mga kaklase.",
                    },
                ],
            }
        },
    },
    { //ARALIN 22222222222
        title: "Aralin 2: Dimensiyon at Epekto ng Globalisasyon",
        activities: {
            "Group Discussion": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Hatiin ang klase sa mga grupo na may 4-5 na miyembro.Sa loob ng 20 minuto, ang bawat grupo ay Kailangang ipresenta ang kanilang mga natuklasan at opinyon tungkol sa iba't ibang dimensyon ng globalisasyon, kabilang ang ekonomiya, kultural, at politikal. Tiyakin na bawat miyembro ay makakapagsalita. Pumili ng isang dimensiyon ng globalisasyon na pagtuunan ng talakayan.  Magtalaga ng isang tagapagsalita na magpapakita ng mga pangunahing punto mula sa talakayan sa buong klase. Pagkatapos ng presentasyon, magbigay ng pagkakataon para sa mga tanong mula sa ibang grupo o mga kaklase.",
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Komprehensibong pagtalakay sa iba't ibang dimensiyon ng globalisasyon",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa lalim sa pangunahing ideya.",
                        column4: "Limitado ang nilalaman at mababaw ang pagtalakay sa paksa.",
                        column5: "Halos hindi nauugnay sa paksa ang nilalaman. "
                    },
                    {
                        column1: "Pagsali ng Bawat Miyembro",
                        column2: "Lahat ng miyembro ay aktibong nakilahok at nag-ambag sa talakayan.",
                        column3: "Karamihan sa mga miyembro ay nakilahok, subalit may ilan na hindi gaanong nag-ambag.",
                        column4: "Kaunti lamang ang nakilahok; hindi sapat ang ambag ng mga miyembro.",
                        column5: "Karamihan sa mga miyembro ay hindi nakilahok sa talakayan."
                    },
                    {
                        column1: "Estruktura",
                        column2: "Malinaw at lohikal ang daloy ng talakayan; mahusay ang pagkakaayos ng mga ideya.",
                        column3: "Maayos ang daloy ng ideya, subalit may kaunting kalituhan.",
                        column4: "Medyo magulo ang pagkakaayos; may mga bahagi na mahirap sundan.",
                        column5: "Walang malinaw na estruktura; mahirap sundan ang daloy ng talakayan."
                    },
                    {
                        column1: "Pagsagot sa Tanong",
                        column2: "Epektibo at maliwanag ang mga sagot sa mga tanong mula sa ibang grupo; mahusay ang pagbuo ng argumento.",
                        column3: "Sapat ang mga sagot subalit may kakulangan sa detalye o naligaw sa ibang paksa.",
                        column4: "Limitado ang kakayahang sumagot at hindi sapat ang mga argumento.",
                        column5: "Hindi nakapagbigay ng malinaw na sagot sa mga tanong at walang wastong argumento."
                    },
                ],
            },
            "Concept Mapping": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Gumawa ng concept map na naglalarawan ng mga koneksyon sa pagitan ng iba't ibang dimensiyon ng globalisasyon, tulad ng ekonomiko, kultural, at politikal. Gumamit ng isang malaking papel (katulad ng manila paper, o white Cartolina) hatiin lamang ito sa ¼  para sa iyong concept map, at isama ang mga pangunahing dimensiyon ng globalisasyon habang ipinapakita ang kanilang mga koneksyon sa pamamagitan ng mga linya at label. Tiyakin na ang iyong concept map ay malinaw, organisado, at madaling maunawaan, at pagkatapos, ipresenta ito sa klase at ipaliwanag ang mga koneksyon sa pagitan ng mga dimensiyon.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at komprehensibong paglalalarawan ng mga dimensiyon at may malinaw ang mga koneksyon.",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa detalye; may mga pangunahing koneksyon.",
                        column4: "Limitado ang nilalaman at mahirap maunawaan ang mga koneksyon.",
                        column5: "Hindi nauugnay sa paksa ang nilalaman."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Napaka-organisado, madaling sundan at mahusay ang pagkakaayos ng mga ideya.",
                        column3: "Maayos ang pagkakaayos subalit may kaunting kalituhan.",
                        column4: "Medyo magulo ang pagkakaayos at; may mga bahagi na mahirap sundan.",
                        column5: "Walang malinaw na estruktura at mahirap sundan ang daloy ng ideya."
                    },
                    {
                        column1: "Kalinawan",
                        column2: "Malinaw at madaling maunawaan ang concept map; mahusay ang mga label at depinisyon.",
                        column3: "Sapat ang kalinawan subalit may ilang bahagi na hindi maliwanag.",
                        column4: "Limitado ang kalinawan dahil maraming bahagi na mahirap intidihin.",
                        column5: "Mahirap intidihin dahil hindi maayos ang mga label at depinisyon."
                    },
                    {
                        column1: "Pagsasalita sa Presentasyon",
                        column2: "Epektibong naipaliwanag ang concept map sa klase; mahusay ang komunikasyon.",
                        column3: "Sapat ang presentasyon subalit may mga pagkakamali sa pagsasalita.",
                        column4: "Limitado ang kakayahang ipaliwanag; maraming hindi maliwanag na bahagi.",
                        column5: "Hindi nakapagbigay ng malinaw na presentasyon dahil walang kaalaman sa paksa."
                    },
                ],
            },
            "Reflection Journal": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Gumawa ng isang reflection journal kung saan isusulat ng mga mag-aaral ang kanilang mga saloobin at opinyon tungkol sa mga epekto ng globalisasyon sa mga dimensiyon tulad ng ekonomiko, kultural, at politikal. Hindi bababa sa 300 salita. Tiyaking gamitin ang mga konkretong halimbawa upang suportahan ang iyong mga argumento. I-submit ang iyong journal sa takdang araw at maging handa na ipresenta ang ilan sa mga pangunahing punto sa klase.",
                format: "• Pamagat: Magbigay ng pamagat na sumasalamin sa iyong pananaw tungkol sa globalisasyon. \n• Introduksyon: Magbigay ng maikling pagpapakilala tungkol sa globalisasyon at ang epekto nito sa ekonomiya, kultura, at politika. \n• Katawan:  Ekonomiko- Ilarawan ang epekto ng globalisasyon sa negosyo, kalakalan, at ekonomiya. Magbigay ng halimbawa. Kultural- talakayin ang mga pagbabago sa kultura at tradisyon dulot ng globalisasyon. Politikal-Ibigay ang mga epekto ng globalisasyon sa politika at relasyon ng mga bansa. \n• Konklusyon: Ibuod ang iyong mga pananaw at magbigay ng personal na opinyon tungkol sa globalisasyon.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malalim at komprehensibong pagtalakay sa mga epekto ng globalisasyon.",
                        column3: "Sapat ang nilalaman, subalit may kakulangan sa detalye.",
                        column4: "Limitado ang nilalaman; mababaw ang pagtalakay sa paksa.",
                        column5: "Halos walang laman o hindi nauugnay sa paksa."
                    },
                    {
                        column1: "Pagsusuri at Saloobin",
                        column2: "Epektibong nailahad ang mga personal na pananaw at pagsusuri.",
                        column3: "May mga personal na pananaw, subalit hindi gaanong malalim. ",
                        column4: "Limitado ang pagsusuri at saloobin at hindi malinaw ang pagkakaunawa sa paksa.",
                        column5: "Walang nailahad na personal na pananaw o pagsusuri."
                    },
                    {
                        column1: "Estruktura",
                        column2: "Maayos ang pagkakasunod-sunod ng mga ideya at mahusay ang pagkakaayos ng sanaysay.",
                        column3: "May kaunting kalituhan sa daloy ng ideya.",
                        column4: "Magulo ang pagkakaayos at may mga bahagi na mahirap sundan.",
                        column5: "Mahirap sundan ang estruktura ng sanaysay. "
                    },
                    {
                        column1: "Paggamit ng Wika",
                        column2: "Wasto at angkop ang gamit ng wika at mahusay ang gramatika at baybay.",
                        column3: "May mga maliit na pagkakamali sa wika, subalit naiintindihan pa rin.",
                        column4: "Maraming pagkakamali sa wika at ito ay nakakaapekto sa pagkaunawa ng mensahe.",
                        column5: "Napakaraming mali sa wika; mahirap intidihin ang mga ideya."
                    },
                ],
            },
            "Mini-Research": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Magsagawa ng mini-research tungkol sa isang tiyak na dimensiyon ng globalisasyon (halimbawa: ekonomiko, kultural, o politikal). Pumili ng isang dimensiyon ng globalisasyon na nais mong pag-aralan at magsaliksik ng impormasyon mula sa mga mapagkakatiwalaang sanggunian, tulad ng mga aklat, artikulo, o internet. Gumawa ng isang simpleng ulat na hindi bababa sa 500 salita at tiyakin na kasama ang mga sanggunian. Ipresenta ang iyong mga natuklasan sa klase sa loob ng 5-7 minuto.",
                format: "• Pamagat:  Isang malinaw at angkop na pamagat na sumasalamin sa napiling dimensiyon ng globalisasyon.\n• Introduksyon: - Maikling pagpapakilala sa napiling dimensiyon ng globalisasyon (hal. ekonomiko, kultural, o politikal). Layunin ng pananaliksik at kahalagahan ng paksa \n • Nilalaman o Katawan: \n Pangunahing Pagsusuri: Maikling talakayan sa mga natuklasan tungkol sa dimensiyon. \nKonkretong Halimbawa: Magbigay ng 2-3 tiyak na halimbawa na sumusuporta sa mga natuklasan.\n• Konklusyon: \nBuod ng pangunahing punto at sariling pananaw.\n Mahahalagang insight o mungkahi batay sa mga natuklasan. \nSanggunian- Talaan ng mga pinagkunan ng impormasyon (aklat, artikulo, website, atbp.).",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman at Lalim ng Pag-aaral",
                        column2: "Malalim na pagsusuri ng paksa na may maraming konkretong halimbawa at sapat na detalye.",
                        column3: "Magandang pagsusuri ng paksa, may mga halimbawa at detalye subalit may kakulangan sa lalim.",
                        column4: "Pagsusuri ng paksa ay mababaw at kulang sa detalye at halimbawa.",
                        column5: "Hindi sapat ang pagsusuri ng paksa, walang konkretong halimbawa."
                    },
                    {
                        column1: "Paggamit ng Sanggunian",
                        column2: "Ang mga sanggunian ay tumpak at mahusay na ginamit upang suportahan ang mga ideya.",
                        column3: "May mga tumpak na sanggunian subalit may ilan na hindi gaanong kapani-paniwala.",
                        column4: "May ilang hindi kapani-paniwala na sanggunian o kulang sa mga sanggunian.",
                        column5: "Walang sapat na katiyakan ang mga ginamit na sanggunian."
                    },
                    {
                        column1: "Organisasyon at Estruktura",
                        column2: "Maayos ang daloy ng mga ideya at malinaw na nakalatag ang mga seksyon ng ulat.",
                        column3: "Maayos ang pagkakasunod-sunod ng ideya subalit may konting kalituhan.",
                        column4: "Mahirap sundan ang pagkakasunod-sunod ng mga ideya, magulo.",
                        column5: "Magulo at mahirap sundan ang estruktura ng ulat."
                    },
                    {
                        column1: "Kalidad ng Presentasyon",
                        column2: "Maliwanag at organisadong presentasyon, epektibong ginamit ang oras at visual aids.",
                        column3: "Maayos ang presentasyon subalit may ilang kahinaan sa pag-aayos ng visual aids o oras.",
                        column4: "Mahirap intidihin ang presentasyon, kulang sa visual aids o hindi maayos ang daloy.",
                        column5: "Hindi maayos ang presentasyon, walang sapat na visual aids at mahirap sundan."
                    },
                ],
            },
            "Globalization Flow Chart: Process and Effects": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "Gumawa ng flow chart na nagpapakita ng proseso kung paano kumikilos ang globalisasyon sa iba't ibang dimensyon at kung paano ito direktang nakaaapekto sa mga bansa. Gamit ang papel o digital tools, ipakita ang step-by-step na proseso ng globalisasyon mula sa pandaigdigang kalakalan, teknolohiya, kultura, at politika. Ibigay ang mga resulta o epekto sa bawat hakbang.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Di-Makatuturan (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at malinaw ang pagpapaliwanag ng bawat hakbang ng proseso ng globalisasyon at may detalyadong mga epekto at tamang mga halimbawa",
                        column3: "Maayos ang pagpapaliwanag ng karamihan ng hakbang, may sapat na detalye sa mga epekto at halimbawa, bagama't may ilang kulang.",
                        column4: "May mga hakbang na hindi lubos na naipaliwanag, at may kakulangan sa mga halimbawa o pag-uugnay ng mga epekto.",
                        column5: "Hindi malinaw ang proseso o walang sapat na detalye ng mga epekto at halimbawa, at maraming impormasyon ang kulang."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Lohikal at sistematiko ang pagkakaayos ng flow chart; madaling sundan ang proseso mula simula hanggang wakas.",
                        column3: "Maayos ang organisasyon ng flow chart, subalit may ilang bahagi na hindi malinaw ang koneksyon o pagkakasunod-sunod.",
                        column4: "Hindi malinaw ang pagkakasunod-sunod ng ilang hakbang, at mahirap sundan ang daloy ng impormasyon.",
                        column5: "Magulo ang organisasyon ng flow chart; mahirap sundan at hindi lohikal ang pagkakasunod-sunod ng mga hakbang."
                    },
                    {
                        column1: "Kalinawan ng Presentasyon",
                        column2: "Napakalinaw at madaling intindihin ang mga label, teksto, at simbolo; gumagamit ng angkop na visual elements para maipaliwanag ang proseso.",
                        column3: "Malinaw ang mga label at simbolo, subalit may ilang bahagi na maaaring mas malinaw o mas organisado ang pagpapakita.",
                        column4: "May mga bahagi ng flow chart na hindi madaling maintindihan o kulang sa kalinawan sa mga label at teksto.",
                        column5: "Hindi malinaw ang mga label, simbolo, at teksto; mahirap intindihin ang kabuuan ng proseso."
                    },
                    {
                        column1: "Kreatibidad",
                        column2: "Malikhaing ginamit ang kulay, hugis, at arrows upang maging kaakit-akit at epektibo ang flow chart bilang visual aid",
                        column3: "May sapat na pagkakagamit ng kulay at mga simbolo, subalit kulang ng mas malikhaing pag-uugnay ng mga visual elements.",
                        column4: "Limitado ang paggamit ng mga kulay at visual elements, na maaaring nakabawas sa epekto ng presentasyon.",
                        column5: "Halos walang pagkakagamit ng kulay o simbolo upang mapahusay ang presentasyon."
                    },
                ],
            },
        },
    },
    { // ARALIN 333333333
        title: "Aralin 3: Kalagayan at Suliranin ng Paggawa sa Bansa sa Konteksto ng Globalisasyon",
        activities: {
            '“Mga Suliranin sa Paggawa” - (Research-Based Assessment)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: 'Magsaliksik at pumili ng isang partikular na suliranin sa paggawa, tulad ng kakulangan ng trabaho o hindi makatarungang pasahod, na dapat isagawa sa loob ng tatlong minuto. Sa loob ng labindalawang minuto, mag-research tungkol sa napiling paksa gamit ang mga aklat, artikulo, at online resources. Gumawa ng dalawang pahinang papel na naglalahad ng mga sanhi at epekto ng napiling suliranin, na dapat matapos sa loob ng limang minuto. Ipresenta ang mga findings sa klase sa loob ng sampung minuto, na may kasamang mga visual aids tulad ng PowerPoint o poster, na may tatlong minuto para sa bawat grupo at isang minutong paghahanda.',
                format: 'Gumawa ng Dalawang Pahinang Papel: \n• Pamagat: Pangalanan ang napiling suliranin (e.g., "Kawalan ng Trabaho sa Pilipinas"). \n• Paglalahad: Isulat ang sanaysay na naglalaman ng mga sumusunod na bahagi: \nIntroduksyon: Maikling pagpapakilala sa napiling suliranin. \n• Sanhi: Ipaliwanag ang mga pangunahing sanhi ng suliranin. \n• Epekto: Ilahad ang mga epekto nito sa manggagawa at komunidad.\n• Konklusyon: Bigyang-diin ang kahalagahan ng pagtugon sa suliraning ito. \n Paghahanda ng Visual Aids (PowerPoint o Poster): \nGumawa ng PowerPoint presentation o poster na naglalaman ng mga pangunahing impormasyon tungkol sa napiling suliranin. \n• Slide 1: Introduksyon sa napiling suliranin. \n• Slide 2: Mga Sanhi ng Suliranin. \n• Slide 3: Mga Epekto ng Suliranin. ',
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Kumpleto at detalyado ang datos, malinaw at maayos ang pagkakasunod-sunod, at may sapat na ebidensya.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Kumpleto ngunit may kaunting kulang sa detalye, maayos ang daloy ngunit may mga pagkakamali.",
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Kailangan ng higit pang datos, hindi ganap na maayos ang pagkakasunod-sunod.",
                    },
                    {
                        column1: "Kailangan ng Pagsasanay (2)",
                        column2: "Walang sapat na impormasyon, magulo ang daloy, at walang ebidensya.",
                    },
                ],
            },
            '“Sulyap sa Nakaraan” - (Historical Analysis)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: "Bigyan ang mga mag-aaral ng tatlong minuto upang pumili ng isang makasaysayang pangyayari na may kaugnayan sa paggawa. Maglaan ng labindalawang minuto para sa mga mag-aaral na magsaliksik tungkol sa kanilang napiling pangyayari. Magbigay ng sampung minuto para sa pagsusulat ng kanilang tatlong-pahinang pagsusuri. Ipresenta nila ang kanilang pagsusuri sa loob ng sampung minuto, na may tatlong minuto para sa bawat mag-aaral at isang minuto para sa paghahanda. Pagkatapos ng bawat presentasyon, maglalaan ng kaunting minuto para sa tanungan at sagutan.",
                format: "Format sa Pagsusuri: \nPamagat: \nI. Panimula (Maikling Pagsusuri sa Pangkalahatang Konteksto ng Pangyayari) \nII. Pagsusuri ng Pangyayari \nIII. Pagtalakay ng Impluwensya at Kahalagahan \nIV. Pagsusuri at Opinyon",
                tableData: [
                    {
                        column1: "Criteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang datos.",
                        column3: "Kumpleto ngunit may kulang sa detalye.",
                        column4: "Kailangan ng higit pang datos.",
                        column5: "Wala o napaka-limited na datos."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos ang pagkakasunod-sunod ng ideya.",
                        column3: "Magandang daloy ngunit may ilang bahagi na magulo.",
                        column4: "Kailangan ng higit pang pag-organisa.",
                        column5: "Magulo ang daloy ng ideya."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Malalim ang pagsusuri sa datos.",
                        column3: "Makabuluhang pagsusuri ngunit may mga pagkukulang.",
                        column4: "Kailangan ng higit pang kritikal na pagsusuri.",
                        column5: "Walang sapat na pagsusuri."
                    },
                    {
                        column1: "Sanggunian",
                        column2: "Sapat at tama ang mga sanggunian.",
                        column3: "May mga sanggunian ngunit hindi sapat.",
                        column4: "Kailangan ng higit pang sanggunian.",
                        column5: "Walang sanggunian."
                    },
                ],
            },
            '“Talumpati ng Pag-asa” - (Speech)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: 'Ang guro ay magtatakda ng grupo sa bawat mag-aaral at magbibigay ng 15 minuto upang lumikha ng isang talumpati na may temang “Pag-asa ng mga Manggagawa,” na dapat hindi bababa sa isang pahina. Pagkatapos nito, maglalaan ng 10 minuto para sa mga mag-aaral na mag-practice ng kanilang talumpati. Sa oras ng presentasyon, bawat mag-aaral ay dapat na iperform ang kanilang talumpati sa harap ng klase sa loob ng 5 minuto. Pagkatapos ng bawat talumpati, bibigyan ng 2-3 minuto ang klase upang magbigay ng feedback at mga tanong.',
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Makabuluhan ang mensahe, maliwanag ang pagbigkas, at nakakaantig ang talumpati.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Mahusay ang mensahe ngunit may ilang pagkakamali sa pagbigkas.",
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Kailangan ng mas malinaw na mensahe at pagbigkas.",
                    },
                    {
                        column1: "Kailangan ng Pagsasanay (2)",
                        column2: "Walang malinaw na mensahe at mahirap intidihin.",
                    },
                ],
            },
            '“Kahulugan ng Manggagawa” - (Art Installation)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: 'Ang klase ay bubuo ng grupo, kung saan ang klase ay hahatiin sa mga grupo ng 4 hanggang 5 na mag-aaral. Magbibigay ng 5 minuto ang guro para pag-usapan ng mga mag-aaral ang isang suliranin ng mga manggagawa na kanilang ilalarawan sa art installation. Bibigyan ng 15 minuto ang mga grupo upang lumikha ng kanilang art installation gamit ang mga recyclable materials. Maglalaan ng 10 minuto upang magsulat ang bawat grupo ng isang maikling paliwanag tungkol sa kanilang gawa. Ang bawat grupo ay magpapresenta ng kanilang installation at paliwanag sa loob ng 5 minuto.',
                tableData: [
                    {
                        column1: "Criteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Estetika",
                        column2: "Kaakit-akit at mahusay ang disenyo.",
                        column3: "Magandang disenyo ngunit may mga aspeto na dapat pagbutihin.",
                        column4: "May ilang kaakit-akit na aspeto ngunit hindi buo.",
                        column5: "Kailangan ng mas maraming pag-iisip sa disenyo."
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Maliwanag ang mensahe ng gawa.",
                        column3: "Nakasaad ang mensahe ngunit hindi ganap na naipahayag.",
                        column4: "Bahagyang nabanggit ang mensahe.",
                        column5: "Walang malinaw na mensahe."
                    },
                    {
                        column1: "Oras ng Pagpresenta",
                        column2: "Naipaliwanag nang maayos at sa tamang oras.",
                        column3: "Naipaliwanag ngunit may mga pagkukulang sa oras.",
                        column4: "Kailangan ng higit pang pagpaplano sa oras.",
                        column5: "Hindi naipaliwanag ng maayos.."
                    },
                    {
                        column1: "Pakikipag-ugnayan",
                        column2: "Nakipag-ugnayan sa mga tagapanood at nakukuha ang atensyon nito.",
                        column3: "May pakikipag-ugnayan ngunit kulang.",
                        column4: "Kailangan ng higit pang pakikipag-ugnayan.",
                        column5: "Walang pakikipag-ugnayan sa mga tagapanood."
                    },
                ],
            },
            '“Musika ng Manggagawa” - (Musical Performance)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: 'Hahatiin ang klase sa mga grupo ng limang mag-aaral. Magbibigay ng limang minuto upang pumili ng tema na may kaugnayan sa mga hamon at tagumpay ng mga manggagawa. Maglalaan ng labinlimang minuto upang lumikha ng isang maikling awitin o parodiya na nagpapakita ng kanilang napiling tema, at pagkatapos mabuo, ensayuhin ang nabuong piyesa para sa pagtatanghal. Ipapakita ng bawat grupo ang kanilang awit sa harap ng klase sa loob ng labinlimang minuto, kung saan tatlong minuto ang ibibigay sa bawat grupo. Pagkatapos ng performance, bibigyan ng dalawa hanggang tatlong minuto ang klase upang magbigay ng feedback at mga tanong.',
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Tama ang tono at ritmo, malinaw ang mensahe ng kanta, at epektibong pagganap ng grupo.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Maayos ang tono ngunit may ilang pagkakamali sa ritmo.",
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Kailangan ng mas mahusay na pagganap at mas malinaw na mensahe.",
                    },
                    {
                        column1: "Kailangan ng Pagsasanay (1)",
                        column2: "Hindi tama ang tono, hindi malinaw ang mensahe, at mahina ang pagganap.",
                    },
                ],
            },
        },
    },
    { // ARALIN 44444444
        title: "Aralin 4: Pagtugon sa Isyu sa Paggawa sa Bansa",
        activities: {
            "Human Graph – Ang Opinyon Ko": {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: 'Maghanda ng malaking espasyo sa silid-aralan. Magtatalaga ng dalawang sulok: isa para sa “Sang-ayon” at isa para sa “Di-Sang-ayon.” Ang bawat mag-aaral ay pipili kung saan siya tatayo base sa kanyang pananaw sa mga pahayag na ilalahad ng guro (e.g., "Dapat bang taasan ang minimum wage?"). Bigyan ang bawat mag-aaral ng 2-3 minuto para ipaliwanag sa klase kung bakit siya pumili ng posisyon. ',
                tableData: [
                    {
                        column1: "Krayterya ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Lohika ng Pagpapaliwanag",
                        column2: "Mahusay na naipaliwanag at malinaw ang dahilan.",
                        column3: "May malinaw na paliwanag ngunit may kulang na detalye.",
                        column4: "Paliwanag ay bahagyang malinaw.",
                        column5: "Walang malinaw na paliwanag."
                    },
                    {
                        column1: "Paglahok",
                        column2: "Aktibo sa lahat ng bahagi ng Gawain.",
                        column3: "Aktibo sa ilang bahagi ng gawain.",
                        column4: "Kalahok ngunit minimal ang kontribusyon.",
                        column5: "Hindi aktibo."
                    },
                    {
                        column1: "Kaangkupan ng Opinyon",
                        column2: "Lubos na akma sa isyu.",
                        column3: "Akma ngunit may kakulangan sa kaugnayan",
                        column4: "Bahagyang may kaugnayan",
                        column5: "Hindi akma"
                    },
                    {
                        column1: "Pakikitungo sa Kaklase",
                        column2: "Maayos na nakikipagdiskurso",
                        column3: "Kadalasang maayos ngunit may ilang pagkukulang",
                        column4: "May mga pagkakataong hindi maayos",
                        column5: "Hindi maayos ang pakikitungo"
                    },
                ],
            },
            'Role-Playing – "Boses ng Manggagawa"': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "Sa pangkat ng 3-4 miyembro, lumikha ng isang maikling role-play na nagpapakita ng karaniwang isyu sa paggawa (e.g., unfair labor practices, mababang pasahod) at kung paano ito nareresolba. Ang bawat grupo ay bibigyan ng 10 minuto upang mag-perform.",
                tableData: [
                    {
                        column1: "Krayterya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Pagganap",
                        column2: "Mahusay at natural ang pagganap",
                        column3: "Natural ngunit may ilang kahinaan",
                        column4: "Puwedeng mapabuti pa",
                        column5: "Hindi natural at walang emosyon"
                    },
                    {
                        column1: "Nilalaman ng Eksena",
                        column2: "Lubos na may kaugnayan sa isyu",
                        column3: "May kaugnayan ngunit kulang sa detalye",
                        column4: "Bahagyang may kaugnayan",
                        column5: "Hindi kaugnay"
                    },
                    {
                        column1: "Pagkakaisa ng Grupo",
                        column2: "Mahusay ang koordinasyon ng bawat isa",
                        column3: "May ilang problema sa koordinasyon",
                        column4: "Mahina ang koordinasyon",
                        column5: "Walang koordinasyon"
                    },
                    {
                        column1: "Oras ng Pagganap",
                        column2: "Nasunod nang eksakto ang oras",
                        column3: "Kaunti lang ang lampas o kulang sa oras",
                        column4: "Halos di nasunod ang oras",
                        column5: "Hindi nasunod ang oras"
                    },
                ],
            },
            'Debate – "Usapang Pasahod"': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "Magkaroon ng debate sa pagitan ng dalawang grupo. Ang isang panig ay pabor sa pagtaas ng minimum wage habang ang isa naman ay hindi sang-ayon. Bibigyan ang bawat grupo ng 5 minuto para sa presentasyon at 3 minuto para sa rebuttal.",
                format: 'Debate Format: "Usapang Pasahod" \nPanimula ng Moderator (1-2 minuto): \nPagpapakilala ng mga Kalahok (1 minuto): \n• Ipakilala ang mga kalahok sa bawat panig: \n• Panig 1 (Pabor sa pagtaas ng minimum wage) \n• Panig 2 (Hindi pabor sa pagtaas ng minimum wage) \nRound 1: Presentasyon ng Panig 1 (5 minuto) \nRound 2: Presentasyon ng Panig 2 (5 minuto) \nRound 3: Rebuttal ng Panig 1 (3 minuto) \nRound 4: Rebuttal ng Panig 2 (3 minuto) \nPagtatapos ng Moderator (1-2 minuto)',
                tableData: [
                    {
                        column1: "Krayterya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Lohika ng Argumento",
                        column2: "Lubos na makatwiran at malinaw",
                        column3: "Makatwiran ngunit may kulang na detalye",
                        column4: "Medyo malinaw ngunit mahina",
                        column5: "Hindi makatwiran"
                    },
                    {
                        column1: "Estruktura ng Debate",
                        column2: "Organisado ang daloy",
                        column3: "Bahagyang organisado",
                        column4: "Hindi malinaw ang daloy",
                        column5: "Walang organisasyon"
                    },
                    {
                        column1: "Pakikitungo sa Katunggali",
                        column2: "Maayos at propesyonal",
                        column3: "May ilang pagkukulang sa propesyonalismo",
                        column4: "Bahagyang hindi propesyonal",
                        column5: "Hindi maayos"
                    },
                    {
                        column1: "Oras ng Pagsasalita",
                        column2: "Nasunod nang eksakto ang oras",
                        column3: "Kaunti lang ang lampas o kulang sa oras",
                        column4: "Halos di nasunod ang oras",
                        column5: "Hindi nasunod ang oras"
                    },
                ],
            },
            'Pananaliksik – "Paggawa at Ekonomiya"': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "Gumawa ng maikling pananaliksik tungkol sa epekto ng sahod sa ekonomiya ng bansa. Ihanda ang isang 2-pahinang ulat at ipresenta ito sa klase nang hindi lalampas sa 5 minuto. Ang mga mag-aaral ay bibigyan ng isang linggo para sa pagbuo, 30 minuto para sa presentasyon.",
                format: "Format ng Pananaliksik: \nI. Pamagat ng Pananaliksik \nII. Panimula (Introduksyon) \nIII. Layunin ng Pananaliksik \nIV. Mga Kahalagahan ng Sahod sa Ekonomiya \nV. Mga Negatibong Epekto ng Mataas na Sahod sa Ekonomiya \nVI. Pag-aaral at Pagsusuri \nVII. Konklusyon \nVIII. Mga Sanggunian",
                tableData: [
                    {
                        column1: "Krayterya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Kalidad ng Pananaliksik",
                        column2: "Lubos na komprehensibo",
                        column3: "Komprehensibo ngunit may kulang",
                        column4: "Medyo may kulang sa datos",
                        column5: "Hindi komprehensibo"
                    },
                    {
                        column1: "Organisasyon ng Ulat",
                        column2: "Mahusay at malinaw ang daloy ng pagsasalita ng tagapag-ulat",
                        column3: "May bahagyang kakulangan sa daloy ng isinagawang pag-ulat",
                        column4: "Bahagyang magulo ang daloy ng pag-uulat na nagbibigay kalituhan sa tagapakinig",
                        column5: "Magulo ang daloy at hindi lubos na maunawaan ang konsepto ng iniuulat."
                    },
                    {
                        column1: "Kaangkupan sa Paksa",
                        column2: "Lubos na angkop ang nahanap na mga impormasyon patungkol sa paksang tinatalakay",
                        column3: "Medyo may kaangkupan ang mga impormasyon ngunit ito’y hindi sapat.",
                        column4: "Bahagyang angkop ang mga impormasyon na may kaugnayan sa paksa ngunit ito’y kulang",
                        column5: "Hindi angkop o tugma ang mga impormasyon tinalakay tungkol sa paksa."
                    },
                    {
                        column1: "Presentasyon",
                        column2: "Mahusay at malinaw na natalakay ang mga mahahalagang punto ng pananaliksik",
                        column3: "Medyo malinaw na natalakay ang punto ng pananaliksik",
                        column4: "Bahagyang malinaw ang pagtalakay sa punto ng pananaliksik ngunit ito’y kulang",
                        column5: "Hindi malinaw at may kalituhan sa detalye ng pananaliksik"
                    },
                ],
            },
            'Infographic – "Isyung Paggawa sa Datos"': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "Lumikha ng infographic na naglalahad ng mga datos tungkol sa unemployment rate sa Pilipinas sa huling limang taon. Ipakita rin ang mga dahilan at posibleng solusyon sa unemployment.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Lubos na organisado, malinaw ang impormasyon, at may makabuluhang datos. Ang infographics ay nagpapakita ng kumpletong detalye at nasasaad ang mga mahahalagang punto ng paksa.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Organisado at may malinaw na datos ngunit may ilang kakulangan sa impormasyon.",
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Bahagyang organisado at kulang sa detalye.",
                    },
                    {
                        column1: "Kailangan ng Pagsasanay (1)",
                        column2: "Hindi organisado at walang sapat na datos.",
                    },
                ],
            },
        },
    },
    { // ARALIN 55555
        title: "Aralin 5: Salik at mga Dahilan ng Migrasyon",
        activities: {
            "Kanta": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon",
                instructions: 'Gumawa ng isang kanta na may haba na 2-3 minuto na tumatalakay sa mga dahilan ng migrasyon sa Pilipinas. ',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Mahusay (4)",
                        column3: "Magaling (3)",
                        column4: "Kailangan pang Pagbutihan (2)",
                        column5: "Hindi Nakatugon (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at komprehensibo ang nilalaman ng kanta. Wasto ang lahat ng impormasyon ",
                        column3: "Karamihan sa impormasyon ay wasto at kumpleto. May ilang bahagi na maaaring hindi gaanong malinaw.",
                        column4: "Mayroong kulang at maling impormasyon sa nilalaman ng kanta. ",
                        column5: "Maraming kakulangan sa nilalaman ng kanta."
                    },
                    {
                        column1: "Presentasyon",
                        column2: "Malikhaing nailalahad ang nilalaman ng kanta. Mayroong maayos na daloy.",
                        column3: "Maayos na nailalahad ang kanta. Nauunawaan ang nilalaman, ngunit may ilang bahaging hindi gaanong malinaw.",
                        column4: "Hindi gaanong maayos na nailalahad ang kanta. Hindi gaanong nauunawaan ang nilalaman.",
                        column5: "Hindi maayos na nailalahad ang kanta. Hindi gaanong nauunawaan ang nilalaman."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Organisado, malinaw, simple at may tamang pagkakasunod-sunod ang presentasyon ng ideya sa kanta/tula.",
                        column3: "Maayos ang presentasyon ng mga pangyayari at ideya. May mga bahaging hindi-gaanong malinaw.",
                        column4: "Hindi gaanong maayos ang presentasyon ng mga pangyayari at ideya. May mga bahaging hindi malinaw.",
                        column5: "Hindi maayos ang presentasyon ng ideya. Maraming bahagi ang hindi malinaw sa paglalahad ng kaisipan."
                    },
                    {
                        column1: "Musika",
                        column2: "Ang musika ay tumutugma sa tema ng kanta at nagbibigay ng emosyonal na epekto. Ang melodiya, ritmo, at harmony ay maayos na pinagsama-sama.",
                        column3: "Ang musika ay tumutugma sa tema ng kanta ngunit may ilang bahagi na hindi gaanong maayos.",
                        column4: "Ang musika ay hindi gaanong tumutugma sa tema ng kanta. May ilang bahagi na hindi maayos.",
                        column5: "Ang musika ay hindi tumutugma sa tema ng kanta. Ang melodiya, ritmo, at harmony ay hindi maayos na pinagsama-sama."
                    },
                ],
            },
            "Poster": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon",
                instructions: 'Gumawa ng isang poster na nagpapakita ng mga salik at dahilan ng migrasyon sa Pilipinas. Maaaring gumamit ng mga larawan, graphics, o ilustrasyon upang maiparating ang mensahe nang mas epektibo. Maaaring gamitin ang mga website tulad ng Canva, Adobe Spark, o iba pang mga online design platform para sa paggawa ng poster.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan pang Magsanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malinaw na naipakita ang mga salik at dahilan ng migrasyon. May iba't ibang uri ng migrasyon ang naipakita.",
                        column3: "Malinaw na naipakita ang mga salik at dahilan ng migrasyon. May ilang uri ng migrasyon ang naipakita.",
                        column4: "Hindi gaanong malinaw ang pagpapakita ng mga salik at dahilan ng migrasyon. May ilang uri ng migrasyon ang naipakita.",
                        column5: "Hindi malinaw ang pagpapakita ng mga salik at dahilan ng migrasyon. Walang naipakita na uri ng migrasyon."
                    },
                    {
                        column1: "Disenyo",
                        column2: "Kaakit-akit at maayos ang disenyo ng poster. Malinaw at madaling maunawaan ang mga larawan at graphics.",
                        column3: "Kaakit-akit at maayos ang disenyo ng poster. Malinaw ang mga larawan at graphics.",
                        column4: "Hindi gaanong kaakit-akit ang disenyo ng poster. Hindi gaanong malinaw ang mga larawan at graphics.",
                        column5: "Hindi kaakit-akit ang disenyo ng poster. Hindi malinaw ang mga larawan at graphics."
                    },
                    {
                        column1: "Orihinal",
                        column2: "Orihinal ang ideya at disenyo ng poster. Hindi ito hango sa ibang mga poster o materyales.",
                        column3: "May ilang bahagi ng poster na hango sa ibang mga poster o materyales.",
                        column4: "Karamihan sa nilalaman ng poster ay hango sa ibang mga poster o materyales.",
                        column5: "Hindi orihinal ang ideya at disenyo ng poster."
                    },
                    {
                        column1: "Mensahe",
                        column2: "Napakahusay na naiparating ang mensahe ng poster.",
                        column3: "Mahusay na naiparating ang mensahe ng poster. ",
                        column4: "Hindi gaanong mahusay na naiparating ang mensahe ng poster.",
                        column5: "Hindi naiparating ang mensahe ng poster. "
                    },
                ],
            },
            "Role-Play": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon",
                instructions: 'Gumawa ng isang role playing ng sitwasyon kung saan ay nagpapaliwanag ng mga dahilan ng paglipat ng isang taong nag-migrate. ',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan pang Magsanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malinaw at tumpak na naipakita ang mga pangunahing konsepto at ideya ng paksa.",
                        column3: "Naipakita ang mga pangunahing konsepto at ideya ng paksa, ngunit may ilang mga pagkakamali at hindi gaanong malinaw.",
                        column4: "May ilang mga pagkakamali sa pag-unawa sa paksa at hindi gaanong malinaw ang paglalahad ng mga konsepto.",
                        column5: "Hindi naipakita ang pag-unawa sa paksa at  hindi malinaw mga ideya na ipinakita."
                    },
                    {
                        column1: "Tauhan",
                        column2: "Mahusay na nagampanan ang mga tauhan, may kumbinasyon ng pagiging tunay, emosyon, at pag-iisip.",
                        column3: "Nagampanan ang mga tauhan ngunit may ilang mga pagkakamali sa pagganap.",
                        column4: "Hindi gaanong mahusay ang pagganap ng mga tauhan, kulang sa pagiging tunay o emosyon.",
                        column5: "Hindi mahusay ang pagganap ng mga tauhan, kulang sa pagiging tunay, emosyon, at pag-iisip."
                    },
                    {
                        column1: "Pagkakaisa",
                        column2: "Ang mga tauhan ay nagpakita ng mahusay na pakikipag-ugnayan at pagkakaisa sa loob ng role playing.",
                        column3: "Ang mga tauhan ay nagpakita ng pakikipag-ugnayan, ngunit may ilang mga pagkakamali sa pagkakaisa.",
                        column4: "Hindi gaanong mahusay ang pakikipag-ugnayan ng mga tauhan, kulang sa pagkakaisa.",
                        column5: "Walang pakikipag-ugnayan at pagkakaisa ang mga tauhan."
                    },
                    {
                        column1: "Pangkalahatang Impresyon",
                        column2: "Ang role play ay nakakaengganyo, nakakaaliw, at nagpapakita ng malalim na pag-unawa sa paksa. Ang mga kalahok ay nagpakita ng kasanayan sa pagganap at pakikipag-ugnayan.",
                        column3: "Ang role play ay nakakaengganyo at nagpapakita ng pag-unawa sa paksa. Ang mga kalahok ay nagpakita ng kasanayan sa pagganap at pakikipag-ugnayan. ",
                        column4: "Ang role play ay hindi gaanong nakakaengganyo at nagpapakita ng limitadong pag-unawa sa paksa. Ang mga kalahok ay nagpakita ng limitadong kasanayan sa pagganap at pakikipag-ugnayan.",
                        column5: "Ang role play ay hindi nakakaengganyo at hindi nagpapakita ng pag-unawa sa paksa. Ang mga kalahok ay hindi nagpakita ng kasanayan sa pagganap at pakikipag-ugnayan. "
                    },
                ],
            },
            "Essay": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon",
                instructions: "Bumuo ng isang sanaysay na nagpapaliwanag ng iba't ibang salik na nagtutulak sa mga tao na lumipat ng tirahan. Ipaliwanag ang mga positibo at negatibong epekto ng migrasyon sa mga taong lumilipat at sa mga bansang kanilang pinupuntahan.",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan pang Pag-unlarin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Lubos na malinaw at makabuluhan ang mensahe ng sanaysay. Nailahad nang maayos ang mga salik ng migrasyon. Naipaliwanag nang detalyado ang mga konsepto.",
                        column3: "Malinaw ang mensahe ng sanaysay at naipakita ang mga salik ng migrasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa.",
                        column4: "Nailahad ang mga pangunahing salik ng migrasyon, ngunit hindi masyadong malinaw ang mga paliwanag. May ilang mga bahagi na hindi organisado. ",
                        column5: "Hindi malinaw ang mga impormasyon tungkol sa mga salik ng migrasyon. Hindi organisado ang sanaysay at mahirap maunawaan."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Malinaw ang pagkakasunod-sunod ng mga ideya. Gumamit ng epektibong mga transisyon upang ikonekta ang mga ideya.",
                        column3: "May ilang bahagi na hindi malinaw ang pagkakasunod-sunod, ngunit naiintindihan pa rin ang pangkalahatang mensahe. Gumamit ng ilang mga transisyon.",
                        column4: "Hindi gaanong organisado ang sanaysay. Mahirap sundan ang daloy ng mga ideya. May ilang mga transisyon, ngunit hindi epektibo.",
                        column5: "Hindi organisado ang sanaysay. Mahirap maunawaan ang pagkakasunod-sunod ng mga ideya. Walang mga transisyon o hindi epektibo ang paggamit ng mga ito."
                    },
                    {
                        column1: "Pagsusulat",
                        column2: "Gumamit ng malinaw, tumpak, at malikhaing wika. Walang mga pagkakamali sa gramatika.",
                        column3: "Gumamit ng malinaw na wika, ngunit may ilang mga pagkakamali sa gramatika.",
                        column4: "Hindi gaanong malinaw ang wika at may ilang mga pagkakamali sa gramatika.",
                        column5: "Hindi malinaw ang wika. Maraming mga pagkakamali sa gramatika. "
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Lubos na malikhain at nakapukaw ng interes ang sanaysay. Gumamit ng mga epektibong halimbawa at mga detalye upang makuha ang atensyon ng mambabasa.",
                        column3: "May ilang mga bahagi na malikhain. Gumamit ng ilang halimbawa at mga detalye, ngunit hindi gaanong epektibo.",
                        column4: "Hindi gaanong malikhain ang sanaysay. Hindi gaanong epektibo ang paggamit ng mga halimbawa, at mga detalye.",
                        column5: "Hindi malikhain ang sanaysay. Walang mga halimbawa o mga detalye."
                    },
                ],
            },
            "Kwento ng Migrante": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon",
                instructions: "Ang bawat mag-aaral ay pipili ng isang migrante (maaaring kathang-isip o tunay na tao) at magsasaliksik tungkol sa kanilang kwento. Pagkatapos, gagawa sila ng maikling presentasyon na isasalaysay ang karanasan ng migrante, ang mga salik na nagtulak sa kanya na lumipat, at ang mga epekto ng migrasyon sa kanyang buhay. Ang presentasyon ay ipipresenta sa harap ng klase. ",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "4 - Napakahusay",
                        column2: "Maayos at nakakaengganyo ang presentasyon. Malinaw at detalyado ang kwento ng migrante, kasama ang mga salik at dahilan ng migrasyon at ang mga epekto nito."
                    },
                    {
                        column1: "3 - Mahusay",
                        column2: "Malinaw ang presentasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa."
                    },
                    {
                        column1: "2 - Katamtaman",
                        column2: "Nailahad ang kwento ng migrante, ngunit hindi masyadong malinaw o detalyado ang mga paliwanag."
                    },
                    {
                        column1: "1 - Kailangan pang Paunlarin",
                        column2: "Hindi malinaw o hindi kumpleto ang kwento ng migrante. Hindi organisado ang presentasyon at mahirap maunawaan."
                    },
                ],
            },
        },
    },
    { // ARALIN 6666666
        title: "Aralin 6: Epekto ng Migrasyon Dulot ng Globalisasyon",
        activities: {
            "Balita": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: 'Magsaliksik ng isang artikulo sa balita tungkol sa mga epekto ng migrasyon dulot ng globalisasyon. Suriin ang artikulo at gumawa ng pagbubuod ng mga pangunahing punto. Ibahagi ang iyong mga natuklasan sa klase.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Pagsasaliksik",
                        column2: "Nakakuha ng isang artikulo sa balita na tumatalakay sa mga epekto ng migrasyon dulot ng globalisasyon. Ang artikulo ay may kredibilidad at may kaugnayan sa paksa.",
                        column3: "Nakakuha ng isang artikulo sa balita na tumatalakay sa mga epekto ng migrasyon dulot ng globalisasyon. Ang artikulo ay may kredibilidad ngunit may limitadong kaugnayan sa paksa.",
                        column4: "Nakakuha ng isang artikulo sa balita na may limitadong kaugnayan sa mga epekto ng migrasyon dulot ng globalisasyon. Ang kredibilidad ng artikulo ay hindi malinaw.",
                        column5: "Hindi nakakuha ng isang artikulo sa balita na tumatalakay sa mga epekto ng migrasyon dulot ng globalisasyon."
                    },
                    {
                        column1: "Pagbubuod",
                        column2: "Nagawa ang isang malinaw at maigsi na pagbubuod ng mga pangunahing punto ng artikulo. Ang pagbubuod ay naglalaman ng mahahalagang detalye at impormasyon.",
                        column3: "Nagawa ang isang pagbubuod ng mga pangunahing punto ng artikulo. Ang pagbubuod ay naglalaman ng ilang mahahalagang detalye at impormasyon.",
                        column4: "Nagawa ang isang pagbubuod ng artikulo ngunit hindi malinaw ang mga pangunahing punto.",
                        column5: "Hindi nagawa ang pagbubuod ng artikulo."
                    },
                    {
                        column1: "Pagbabahagi",
                        column2: "Nagawa ang isang malinaw at organisadong pagbabahagi ng mga natuklasan sa klase. Ang pagbabahagi ay naglalaman ng mga karagdagang insights at pagsusuri.",
                        column3: "Nagawa ang isang pagbabahagi ng mga natuklasan sa klase. Ang pagbabahagi ay naglalaman ng ilang karagdagang insights at pagsusuri.",
                        column4: "Nagawa ang pagbabahagi ng mga natuklasan sa klase ngunit hindi malinaw ang presentasyon.",
                        column5: "Hindi nagawa ang pagbabahagi ng mga natuklasan sa klase."
                    },
                    {
                        column1: "Kasanayan sa Pagsasalita",
                        column2: "Nagpakita ng malinaw na pagsasalita at pagpapahayag ng mga ideya. Ang pagsasalita ay naglalaman ng tamang paggamit ng gramatika at bokabularyo.",
                        column3: "Nagpakita ng malinaw na pagsasalita at pagpapahayag ng mga ideya. Ang pagsasalita ay naglalaman ng ilang pagkakamali sa gramatika at bokabularyo.",
                        column4: "Nagpakita ng pagsasalita ngunit hindi malinaw ang pagpapahayag ng mga ideya. Ang pagsasalita ay naglalaman ng maraming pagkakamali sa gramatika at bokabularyo.",
                        column5: "Hindi nagpakita ng malinaw na pagsasalita at pagpapahayag ng mga ideya. Ang pagsasalita ay naglalaman ng maraming pagkakamali sa gramatika at bokabularyo."
                    },
                ],
            },
            "Debate": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: 'Magsagawa ng isang maikling debate tungkol sa mga isyu ng migrasyon dulot ng globalisasyon. Hatiin ang klase sa dalawang grupo: ang mga sumusuporta sa migrasyon at ang mga tumututol.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Ang mga argumento ay tumpak, malinaw, at nakabatay sa ebidensya. Ang mga argumento ay nagpapakita ng malalim na pag-unawa sa mga isyu ng migrasyon dulot ng globalisasyon. Ang mga argumento ay nagpapakita ng pag-unawa sa iba't ibang pananaw at perspektibo.",
                        column3: "Ang mga argumento ay tumpak at malinaw. Ang mga argumento ay nagpapakita ng pag-unawa sa mga isyu ng migrasyon dulot ng globalisasyon.",
                        column4: "Ang mga argumento ay hindi gaanong tumpak o malinaw. Ang mga argumento ay hindi nagpapakita ng malalim na pag-unawa sa mga isyu ng migrasyon dulot ng globalisasyon.",
                        column5: "Ang mga argumento ay hindi tumpak o malinaw. Ang mga argumento ay hindi nagpapakita ng pag-unawa sa mga isyu ng migrasyon dulot ng globalisasyon."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Ang mga argumento ay orihinal at malikhain. Ang mga paraan ng paglalahad ay nakaka-engganyo at nagpapakita ng pagkamalikhain. Ang mga debater ay nagpakita ng pagkamalikhain sa paggamit ng mga visual aids, datos, o iba pang paraan ng pagtatanghal.",
                        column3: "Ang mga argumento ay malikhain. Ang mga paraan ng paglalahad ay nakaka-engganyo.",
                        column4: "Ang mga argumento ay hindi gaanong malikhain. Ang mga paraan ng paglalahad ay hindi gaanong nakaka-engganyo.",
                        column5: "Ang mga argumento ay hindi malikhain. Ang mga paraan ng paglalahad ay hindi nakaka-engganyo."
                    },
                    {
                        column1: "Pagganap",
                        column2: "Ang mga debater ay nagpakita ng kasanayan sa pagtatalo at paglalahad ng mga argumento. Ang kanilang mga boses, tono, at ekspresyon ay tumutugma sa kanilang mga argumento. Ang mga debater ay nagpakita ng kumpiyansa at propesyonalismo sa kanilang pagganap.",
                        column3: "Ang mga debater ay nagpakita ng kasanayan sa pagtatalo at paglalahad ng mga argumento. Ang kanilang mga boses, tono, at ekspresyon ay tumutugma sa kanilang mga argumento.",
                        column4: "Ang mga debater ay hindi gaanong nagpakita ng kasanayan sa pagtatalo at paglalahad ng mga argumento. Ang kanilang mga boses, tono, at ekspresyon ay hindi gaanong tumutugma sa kanilang mga argumento.",
                        column5: "Ang mga debater ay hindi nagpakita ng kasanayan sa pagtatalo at paglalahad ng mga argumento. Ang kanilang mga boses, tono, at ekspresyon ay hindi tumutugma sa kanilang mga argumento."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Ang debate ay nagpapakita ng malalim na pagsusuri sa mga isyu ng migrasyon dulot ng globalisasyon. Ang mga debater ay nagpakita ng kakayahan na mag-isip nang kritikal at magbigay ng mga matibay na argumento. Ang debate ay nagbigay ng pagkakataon para sa mga mag-aaral na mag-isip nang malalim tungkol sa mga epekto ng migrasyon sa lipunan.",
                        column3: "Ang debate ay nagpapakita ng pagsusuri sa mga isyu ng migrasyon dulot ng globalisasyon. Ang mga debater ay nagpakita ng kakayahan na mag-isip nang kritikal.",
                        column4: "Ang debate ay nagpapakita ng limitadong pagsusuri sa mga isyu ng migrasyon dulot ng globalisasyon. Ang mga debater ay hindi gaanong nagpakita ng kakayahan na mag-isip nang kritikal.",
                        column5: "Ang debate ay hindi nagpapakita ng pagsusuri sa mga isyu ng migrasyon dulot ng globalisasyon. Ang mga debater ay hindi nagpakita ng kakayahan na mag-isip nang kritikal."
                    },
                ],
            },
            "Collage": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: 'Gumawa ng isang collage na nagpapakita ng mga epekto ng migrasyon dulot ng globalisasyon. Maaaring magamit ang mga larawan, salita, at iba pang materyales.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malinaw na naipakita ang mga epekto ng migrasyon dulot ng globalisasyon. Ang mga larawan, salita, at materyales ay tumpak na naglalarawan ng mga isyung ito.",
                        column3: "Naipakita ang mga epekto ng migrasyon, ngunit hindi gaanong malinaw. Ang mga larawan, salita, at materyales ay tumpak na naglalarawan ng mga isyung ito.",
                        column4: "Hindi gaanong malinaw ang pagpapakita ng mga epekto ng migrasyon. Ang mga larawan, salita, at materyales ay hindi gaanong tumpak na naglalarawan ng mga isyung ito.",
                        column5: "Hindi naipakita ang mga epekto ng migrasyon. Ang mga larawan, salita, at materyales ay hindi tumpak na naglalarawan ng mga isyung ito."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Ang collage ay orihinal, malikhain, at nakakaakit. Ang mga kulay, komposisyon, at pag-aayos ay nagpapakita ng pagkamalikhain.",
                        column3: "Ang collage ay malikhain. Ang mga kulay, komposisyon, at pag-aayos ay nagpapakita ng pagkamalikhain.",
                        column4: "Ang collage ay hindi gaanong malikhain. Ang mga kulay, komposisyon, at pag-aayos ay hindi gaanong nagpapakita ng pagkamalikhain.",
                        column5: "Ang collage ay hindi malikhain. Ang mga kulay, komposisyon, at pag-aayos ay hindi nagpapakita ng pagkamalikhain."
                    },
                    {
                        column1: "Paglalahad",
                        column2: "Ang collage ay maayos na inilahad at madaling maunawaan. Ang mga label, caption, at impormasyon ay malinaw at madaling basahin.",
                        column3: "Ang collage ay maayos na inilahad. Ang mga label, caption, at impormasyon ay malinaw.",
                        column4: "Ang collage ay hindi gaanong maayos na inilahad. Ang mga label, caption, at impormasyon ay hindi gaanong malinaw.",
                        column5: "Ang collage ay hindi maayos na inilahad. Ang mga label, caption, at impormasyon ay hindi malinaw."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Ang collage ay nagpapakita ng malalim na pag-unawa sa mga epekto ng globalisasyon sa migrasyon.",
                        column3: "Ang collage ay nagpapakita ng pag-unawa sa mga epekto ng globalisasyon sa migrasyon.",
                        column4: "Ang collage ay nagpapakita ng limitadong pag-unawa sa mga epekto ng globalisasyon sa migrasyon.",
                        column5: "Ang collage ay hindi nagpapakita ng pag-unawa sa mga epekto ng globalisasyon sa migrasyon."
                    },
                ],
            },
            "Blog Post": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: 'Sumulat ng isang blog post na nagbabahagi ng iyong mga kaalaman at pananaw tungkol sa mga epekto ng migrasyon dulot ng globalisasyon. Maaaring mag-focus sa mga epekto sa kultura, ekonomiya, o lipunan. Maaari kang magbigay ng mga halimbawa, kwento, o datos upang suportahan ang iyong mga argumento. \nTandaan: \n• Gumamit ng malinaw at madaling maunawaan na wika.\n• Magbigay ng mga tiyak na halimbawa at datos upang suportahan ang iyong mga argumento.\n• Maging malikhain at nakaka-engganyo sa iyong pagsulat.\n• Isama ang isang malinaw na pamagat at isang maikling introduksyon.\n• Magbigay ng konklusyon na nagbubuod sa iyong mga pangunahing punto.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Nilalaman at Pag-unawa",
                        column2: "Malinaw at malalim ang pag-unawa sa mga epekto ng migrasyon dulot ng globalisasyon. Nagbibigay ng mga tiyak na halimbawa, kwento, o datos upang suportahan ang mga argumento.",
                        column3: "Malinaw ang pag-unawa sa mga epekto ng migrasyon dulot ng globalisasyon. Nagbibigay ng ilang halimbawa o datos upang suportahan ang mga argumento.",
                        column4: "May limitadong pag-unawa sa mga epekto ng migrasyon dulot ng globalisasyon. Ang mga halimbawa o datos ay hindi sapat o hindi tiyak.",
                        column5: "Hindi naipakita ang pag-unawa sa mga epekto ng migrasyon dulot ng globalisasyon. Walang mga halimbawa o datos na ibinigay."
                    },
                    {
                        column1: "Organisasyon at Istraktura",
                        column2: "Malinaw at lohikal ang organisasyon ng blog post. May malinaw na introduksyon, katawan, at konklusyon. Ang mga talata ay maayos na nakakonekta sa isa't isa.",
                        column3: "May malinaw na organisasyon ng blog post. May introduksyon, katawan, at konklusyon. Ang mga talata ay medyo nakakonekta sa isa't isa.",
                        column4: "Hindi gaanong malinaw ang organisasyon ng blog post. Ang mga talata ay hindi maayos na nakakonekta sa isa't isa.",
                        column5: "Walang malinaw na organisasyon ng blog post. Walang introduksyon, katawan, o konklusyon."
                    },
                    {
                        column1: "Wika at Estilo",
                        column2: "Malinaw, tumpak, at madaling maunawaan ang wika. Ang estilo ng pagsulat ay malikhain at nakaka-engganyo.",
                        column3: "Malinaw at tumpak ang wika. Ang estilo ng pagsulat ay medyo nakaka-engganyo.",
                        column4: "Hindi gaanong malinaw o tumpak ang wika. Ang estilo ng pagsulat ay hindi nakaka-engganyo.",
                        column5: "Hindi malinaw at tumpak ang wika. Ang estilo ng pagsulat ay hindi nakaka-engganyo."
                    },
                    {
                        column1: "Pagkamalikhain at Pagka-orihinal",
                        column2: "Napakamalikhain at orihinal ng blog post. Nagpapakita ng malalim na pag-iisip at pagka-malikhain.",
                        column3: "May ilang halimbawa ng pagkamalikhain at pagka-orihinal.",
                        column4: "Hindi gaanong malikhain o orihinal ang blog post.",
                        column5: "Hindi malikhain at orihinal ang blog post."
                    },
                ],
            },
            "Infographics": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: 'Pumili ng isang partikular na epekto ng migrasyon dulot ng globalisasyon (halimbawa, epekto sa ekonomiya, kultura, o lipunan) at lumikha ng isang infographic na nagpapakita ng mga mahahalagang impormasyon tungkol dito. Maaaring gamitin ang mga larawan, tsart, grap, at iba pang biswal na elemento upang gawing mas nakakaakit at madaling maunawaan ang iyong infographics.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malinaw at tumpak na nagpapakita ng napiling epekto ng migrasyon dulot ng globalisasyon. Nagbibigay ng sapat na impormasyon at mga halimbawa upang maunawaan ang paksa.",
                        column3: "Nagpapakita ng napiling epekto ng migrasyon dulot ng globalisasyon, ngunit may ilang kulang na impormasyon o halimbawa.",
                        column4: "Nagpapakita ng ilang impormasyon tungkol sa napiling epekto, ngunit hindi sapat upang maunawaan ang paksa.",
                        column5: "Hindi malinaw at hindi tumpak na nagpapakita ng napiling epekto ng migrasyon dulot ng globalisasyon."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Malinaw at lohikal na organisado ang impormasyon. Madaling sundan ang daloy ng impormasyon at madaling maunawaan ang mga pangunahing punto.",
                        column3: "May ilang bahagi ng infographic na hindi malinaw o hindi lohikal na organisado.",
                        column4: "Hindi malinaw ang organisasyon ng impormasyon. Mahirap sundan ang daloy ng impormasyon.",
                        column5: "Walang organisasyon ang impormasyon."
                    },
                    {
                        column1: "Biswal na Presentasyon",
                        column2: "Gumamit ng iba't ibang biswal na elemento (mga larawan, tsart, grap, atbp.) upang gawing mas nakakaakit at madaling maunawaan ang infographic. Ang mga biswal na elemento ay tumutulong sa pagpapaliwanag ng impormasyon.",
                        column3: "Gumamit ng ilang biswal na elemento, ngunit hindi gaanong epektibo sa pagpapaliwanag ng impormasyon.",
                        column4: "May ilang biswal na elemento, ngunit hindi tumutulong sa pagpapaliwanag ng impormasyon.",
                        column5: "Walang biswal na elemento at hindi epektibo ang mga ito sa pagpapaliwanag ng impormasyon."
                    },
                    {
                        column1: "Kalinisan at Kaayusan ",
                        column2: "Malinis at maayos ang infographic. Madaling basahin at maunawaan ang lahat ng impormasyon.",
                        column3: "May ilang bahagi ng infographic na hindi malinis o maayos.",
                        column4: "Hindi malinis o maayos ang infographic. Mahirap basahin at maunawaan ang impormasyon.",
                        column5: "Hindi malinis at maayos ang infographic. Mahirap basahin at maunawaan ang impormasyon."
                    },
                ],
            },
        },
    },
    { // ARALIN 77777777
        title: "Aralin 7: Epekto ng Globalisasyon sa Aspektong Pang-Ekonomiya, Pangteknolohikal, Panlipunan at Sosyo-Kultural",
        activities: {
            "Poster": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural",
                instructions: "Gamit ang 1/4 illustration board, ipakita ang epekto ng globalisasyon sa mga aspektong pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural sa pamamagitan ng isang poster. Maaring gumamit ng iba’t ibang simbolo at elemento upang maipakita ng malinaw ang mensahe",
                tableData: [
                    {
                        column1: "Kategorya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at malalim ang pagpapaliwanag ng epekto ng globalisasyon sa bawat aspeto (pang-ekonomiya, pangteknolohikal, panlipunan, sosyo-kultural). Malinaw ang mensahe.",
                        column3: "Naipapakita ang epekto ng globalisasyon sa bawat aspeto, ngunit may ilang aspeto na maaaring mapalalim pa ang paliwanag.",
                        column4: "Inilalarawan ang bawat aspeto, ngunit kulang sa detalyadong pagpapaliwanag at hindi lahat ng aspeto ay malinaw na ipinapakita.",
                        column5: "Hindi sapat ang impormasyon; kulang o hindi malinaw ang pagpapahayag ng epekto ng globalisasyon sa mga aspeto."
                    },
                    {
                        column1: "Kreatibidad",
                        column2: "Lubos na malikhain at orihinal ang disenyo, kaakit-akit, at mahusay na nagtatampok ng mensahe gamit ang mga simbolo at visual.",
                        column3: "Malikhain at maayos ang disenyo, ngunit may puwang para mapaganda pa ang ilang bahagi.",
                        column4: "Simpleng disenyo, may kakaunting malikhaing aspekto ngunit hindi gaanong kaakit-akit.",
                        column5: "Minimal o hindi kapansin-pansin ang disenyo, walang sapat na malikhaing elemento na nagpapahayag ng mensahe."
                    },
                    {
                        column1: "Kaayusan",
                        column2: "Organisado at malinaw ang pagkakaayos ng mga elemento ng poster. Ang impormasyon ay madaling basahin at sundan.",
                        column3: "Maayos ang layout, ngunit may ilang bahaging magulo o hindi pantay-pantay ang pagkakaayos ng mga elemento.",
                        column4: "May mga bahagi ng poster na magulo, mahirap sundan ang daloy ng impormasyon o hindi malinaw ang ilang bahagi.",
                        column5: "Hindi organisado ang pagkakaayos ng mga elemento, at mahirap intindihin ang daloy ng impormasyon."
                    },
                    {
                        column1: "Kalinawan ng Mensahe",
                        column2: "Malinaw na ipinapakita ang epekto ng globalisasyon sa lahat ng aspeto. Ang mensahe ay madaling maunawaan at kapansin-pansin.",
                        column3: "Naipapakita ang mensahe, ngunit may ilang bahagi na maaaring hindi ganap na malinaw o kailangan pang ipaliwanag nang mas detalyado.",
                        column4: "Hindi gaanong malinaw ang mensahe; may mga bahaging hindi tugma sa mga visual o hindi sapat ang pagpapaliwanag.",
                        column5: "Hindi malinaw o hindi nauunawaan ang mensahe ng poster. Walang sapat na paliwanag tungkol sa epekto ng globalisasyon."
                    },
                ],
            },
            "Photo Collage": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "1. Gumawa ng isang collage na naglalarawan ng epekto ng globalisasyon sa mga aspektong pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural. \n2. Gumamit ng iba't ibang materyales tulad ng mga larawan, magazine clippings, at iba pang visual na elemento upang ipahayag ang iyong mga ideya. \n3. Tiyaking maayos at malinis ang pagkakadikit ng mga materyales sa collage. \n4. Isama ang mga maiikli at malinaw na paglalarawan o caption para sa bawat bahagi ng collage.",
                tableData: [
                    {
                        column1: "Kategorya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at malalim ang paglalarawan ng epekto ng globalisasyon sa bawat aspeto (pang-ekonomiya, pangteknolohikal, panlipunan, sosyo-kultural).",
                        column3: "Naipapakita ang epekto ng globalisasyon sa bawat aspeto, ngunit may ilang aspeto na maaaring mapalalim pa ang paliwanag.",
                        column4: "Inilalarawan ang bawat aspeto, ngunit kulang sa detalyadong pagpapaliwanag at hindi lahat ng aspeto ay malinaw na ipinapakita.",
                        column5: "Hindi sapat ang impormasyon; kulang o hindi malinaw ang pagpapahayag ng epekto ng globalisasyon sa mga aspeto."
                    },
                    {
                        column1: "Kreatibidad",
                        column2: "Lubos na malikhain at orihinal ang paggamit ng iba't ibang materyales, maayos na nakalagay, at mahusay na nagtatampok ng mensahe.",
                        column3: "Malikhain ang collage, ngunit may puwang para mapaganda pa ang ilang bahagi o paggamit ng materyales.",
                        column4: "Simpleng collage na may kaunting malikhaing aspekto ngunit hindi gaanong kaakit-akit.",
                        column5: "Minimal o hindi kapansin-pansin ang disenyo, walang sapat na malikhaing elemento na nagpapahayag ng mensahe."
                    },
                    {
                        column1: "Kaayusan",
                        column2: "Organisado at malinaw ang pagkakaayos ng mga elemento sa collage. Ang impormasyon ay madaling basahin at sundan.",
                        column3: "Maayos ang layout, ngunit may ilang bahaging magulo o hindi pantay-pantay ang pagkakaayos ng mga elemento.",
                        column4: "May mga bahagi ng collage na magulo, mahirap sundan ang daloy ng impormasyon o hindi malinaw ang ilang bahagi.",
                        column5: "Hindi organisado ang pagkakaayos ng mga elemento, at mahirap intindihin ang daloy ng impormasyon."
                    },
                    {
                        column1: "Kalinawan ng Mensahe",
                        column2: "Malinaw na ipinapakita ang epekto ng globalisasyon sa lahat ng aspeto. Ang mensahe ay madaling maunawaan at kapansin-pansin.",
                        column3: "Naipapakita ang mensahe, ngunit may ilang bahagi na maaaring hindi ganap na malinaw o kailangan pang ipaliwanag nang mas detalyado.",
                        column4: "Hindi gaanong malinaw ang mensahe; may mga bahaging hindi tugma sa mga visual o hindi sapat ang pagpapaliwanag.",
                        column5: "Hindi nauunawaan ang mensahe ng collage. Walang sapat na paliwanag tungkol sa epekto ng globalisasyon."
                    },
                ],
            },
            "Concept Mapping": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "1. Gumawa ng isang concept map na naglalarawan ng epekto ng globalisasyon sa mga aspektong pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural. \n2. I-visualize ang koneksyon at relasyon ng bawat aspeto gamit ang mga hugis at linya.\n3. Gumamit ng mga salita o maikling parirala upang ipaliwanag ang bawat bahagi ng concept map.",
                tableData: [
                    {
                        column1: "Kategorya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at malalim ang paglalarawan ng epekto ng globalisasyon sa bawat aspeto (pang-ekonomiya, pangteknolohikal, panlipunan, sosyo-kultural).",
                        column3: "Naipapakita ang epekto ng globalisasyon sa bawat aspeto, ngunit may ilang aspeto na maaaring mapalalim pa ang paliwanag.",
                        column4: "Inilalarawan ang bawat aspeto, ngunit kulang sa detalyadong pagpapaliwanag at hindi lahat ng aspeto ay malinaw na ipinapakita.",
                        column5: "Hindi sapat ang impormasyon; kulang o hindi malinaw ang pagpapahayag ng epekto ng globalisasyon sa mga aspeto."
                    },
                    {
                        column1: "Kreatibidad",
                        column2: "Lubos na malikhain at orihinal ang paggamit ng iba't ibang materyales, maayos na nakalagay, at mahusay na nagtatampok ng mensahe.",
                        column3: "Malikhain ang collage, ngunit may puwang para mapaganda pa ang ilang bahagi o paggamit ng materyales.",
                        column4: "Simpleng collage na may kaunting malikhaing aspekto ngunit hindi gaanong kaakit-akit.",
                        column5: "Minimal o hindi kapansin-pansin ang disenyo, walang sapat na malikhaing elemento na nagpapahayag ng mensahe."
                    },
                    {
                        column1: "Kaayusan",
                        column2: "Organisado at malinaw ang pagkakaayos ng mga elemento sa collage. Ang impormasyon ay madaling basahin at sundan.",
                        column3: "Maayos ang layout, ngunit may ilang bahaging magulo o hindi pantay-pantay ang pagkakaayos ng mga elemento.",
                        column4: "May mga bahagi ng collage na magulo, mahirap sundan ang daloy ng impormasyon o hindi malinaw ang ilang bahagi.",
                        column5: "Hindi organisado ang pagkakaayos ng mga elemento, at mahirap intindihin ang daloy ng impormasyon."
                    },
                    {
                        column1: "Kalinawan ng Mensahe",
                        column2: "Malinaw na ipinapakita ang epekto ng globalisasyon sa lahat ng aspeto. Ang mensahe ay madaling maunawaan at kapansin-pansin.",
                        column3: "Naipapakita ang mensahe, ngunit may ilang bahagi na maaaring hindi ganap na malinaw o kailangan pang ipaliwanag nang mas detalyado.",
                        column4: "Hindi gaanong malinaw ang mensahe; may mga bahaging hindi tugma sa mga visual o hindi sapat ang pagpapaliwanag.",
                        column5: "Hindi nauunawaan ang mensahe ng collage. Walang sapat na paliwanag tungkol sa epekto ng globalisasyon."
                    },
                ],
            },
            "Reflection": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "Sa pamamagitan ng 300-500 na salita, sumulat ng isang repleksyon na naglalarawan ng iyong mga natutunan tungkol sa epekto ng globalisasyon sa mga aspektong pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural.",
                tableData: [
                    {
                        column1: "Kategorya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang mga ideya tungkol sa epekto ng globalisasyon. Malinaw na naipahayag ang mga positibo at negatibong epekto nito.",
                        column3: "Naipapakita ang mga ideya tungkol sa epekto ng globalisasyon, ngunit may ilang bahagi na maaaring mapalalim pa ang paliwanag.",
                        column4: "Inilalarawan ang mga ideya, ngunit kulang sa detalyadong pagpapaliwanag at hindi lahat ng aspeto ay malinaw na nakikita.",
                        column5: "Hindi sapat ang impormasyon; kulang o hindi malinaw ang pagpapahayag ng epekto ng globalisasyon sa mga aspeto."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos at malinaw ang pagkakasunod-sunod ng mga ideya. Ang mga pangungusap ay konektado at madaling sundan.",
                        column3: "Maganda ang pagkakaayos ng mga ideya, ngunit may ilang bahagi na mahirap sundan o intindihin.",
                        column4: "Ang pagkakasunod-sunod ng mga ideya ay medyo magulo, mahirap intidihin ang daloy ng kaisipan.",
                        column5: "Hindi organisado ang pagkakasunod-sunod ng mga ideya; mahirap intindihin ang daloy ng repleksyon."
                    },
                    {
                        column1: "Kreatibidad at Orihinalidad",
                        column2: "Lubos na malikhain at orihinal ang pagsasalaysay ng mga ideya. Ang mga pananaw ay naiiba at nagbibigay ng bagong pananaw sa paksa.",
                        column3: "Malikhain ang repleksyon, ngunit may ilang bahagi na maaaring mas malikhain o wala masyadong bago na pananaw.",
                        column4: "May ilang bahagi na di gaanong orihinal o malikhaing nailahad; kulang sa bagong pananaw.",
                        column5: "Minimal o walang orihinal na pananaw; hindi nagpapahayag ng sariwang ideya o pananaw."
                    },
                    {
                        column1: "Kalinawan at Wasto ng Wika",
                        column2: "Malinaw ang paggamit ng wika; walang gramatikal na pagkakamali. Ang mga pangungusap ay maayos at angkop ang bantas.",
                        column3: "Maayos ang paggamit ng wika, ngunit may ilang maliliit na pagkakamali sa gramatika o bantas.",
                        column4: "May mga pagkakamali sa gramatika o bantas na nagiging hadlang sa pagkaunawa ng mensahe.",
                        column5: "Maraming pagkakamali sa gramatika at bantas na nagiging sanhi ng kalituhan sa mensahe ng repleksyon."
                    },
                ],
            },
            "Slogan": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "Gumawa ng isang slogon tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                tableData: [
                    {
                        column1: "Kategorya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Ang slogan ay malinaw na nagpapahayag ng epekto ng globalisasyon at naglalaman ng makabuluhang mensahe na nauugnay sa mga aspeto nito.",
                        column3: "Naipapakita ang mensahe tungkol sa epekto ng globalisasyon, ngunit may ilang bahagi na maaaring mas mapalalim o mas maging tiyak.",
                        column4: "Ang mensahe ng slogan ay hindi gaanong malinaw; may mga aspeto na kulang sa detalye o hindi nauugnay nang mabuti sa globalisasyon.",
                        column5: "Ang slogan ay hindi naglalaman ng sapat na impormasyon at hindi nagpapahayag ng mensahe tungkol sa epekto ng globalisasyon."
                    },
                    {
                        column1: "Kreatibidad",
                        column2: "Napaka-orihinal at malikhaing disenyo ng slogan. Ang mga elemento at istilo ay nagdaragdag ng kagandahan at bisa ng mensahe.",
                        column3: "Malikhain ang slogan, ngunit may puwang para mapabuti ang disenyo o paggamit ng mga elemento.",
                        column4: "Simpleng slogan na may kaunting malikhaing aspekto; hindi gaanong kaakit-akit ang presentasyon.",
                        column5: "Minimal o walang malikhaing elemento na nagpapahayag ng mensahe; walang sapat na porma o presentasyon na nagbibigay ng interes."
                    },
                    {
                        column1: "Kalinawan ng Mensahe",
                        column2: "Ang mensahe ng slogan ay malinaw, madaling maunawaan, at kapansin-pansin.",
                        column3: "Ang mensahe ng slogan ay naiintindihan, ngunit may ilang bahagi na maaaring hindi ganap na malinaw o kailangan pang ipaliwanag nang mas detalyado.",
                        column4: "ng mensahe ng slogan ay hindi gaanong maliwanag; may mga bahaging hindi sapat ang paliwanag.",
                        column5: "Ang slogan ay hindi malinaw o mahirap intidihin; walang sapat na paliwanag tungkol sa epekto ng globalisasyon."
                    },
                ],
            },
        },
    },
    { // ARALIN 888888888
        title: "Aralin 8: Positibo at Negatibong Saloobin Hinggil sa mga Piling Aspekto ng Globalisasyon",
        activities: {
            "Infographics": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: 'Gumawa ng isang Infographic na nagpapakita ng positibo at negatibo epekto sa mga piling aspekto (pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural) ng globalisasyon.',
                tableData: [
                    {
                        column1: "Kategorya ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang nilalaman ng infographic. Malinaw na nailahad ang positibo at negatibong saloobin sa bawat aspeto.",
                        column3: "May sapat na detalye ang nilalaman, ngunit may ilang bahagi na maaaring mas mapalalim o maging mas malinaw.",
                        column4: "Kulang ang detalye o may ilang aspeto ng saloobin na hindi ganap na nailahad.",
                        column5: "Kakulangan sa nilalaman; maraming aspeto ang hindi nabanggit o hindi malinaw ang mga saloobin."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Mahusay ang pagkakaayos ng impormasyon; madaling sundan at nauugnay ang mga bahagi sa isa’t isa.",
                        column3: "Organisado ang infographic, ngunit may ilang bahagi na maaaring higit pang ayusin para sa mas magandang daloy ng impormasyon.",
                        column4: "Medyo magulo ang pagkakaayos; hindi ganap na malinaw ang pagkakasunod-sunod ng mga ideya.",
                        column5: "Hindi organisado; mahirap sundan ang daloy ng impormasyon at hindi malinaw ang ugnayan ng mga bahagi."
                    },
                    {
                        column1: "Visual Appeal",
                        column2: "Napaka-kaakit-akit ng design; mahusay na paggamit ng kulay, font, at graphics upang suportahan ang mensahe.",
                        column3: "Kaakit-akit ang design, ngunit may ilang aspeto na maaaring mas pagandahin o gawing mas akma sa tema.",
                        column4: "Kulang sa creative design; ang ilang visuals ay hindi umuugnay sa nilalaman ng infographic.",
                        column5: "Halos walang ginamit na visual elements o hindi kaakit-akit ang presentation; mahirap intidihin ang mensahe."
                    },
                ],
            },
            "Reflection": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: '1. Sumulat ng isang reflection na naglalaman ng iyong mga saloobin bilang isang estudyante tungkol sa mga positibo at negatibong aspeto ng globalisasyon na iyong natutunan sa mga naunang gawain. \n2. Isama ang mga sumusunod na bahagi sa iyong reflection: \n• Panimula: Maikling pagpapakilala tungkol sa layunin ng iyong reflection.\n• Mga Positibong Saloobin: Ilahad ang mga positibong epekto ng globalisasyon na iyong napansin o naranasan.\n• Mga Negatibong Saloobin: Ilahad ang mga negatibong epekto ng globalisasyon na iyong napansin o naranasan.\n• Pagsusuri at Pagninilay: Suriin kung paano nakakaapekto ang globalisasyon sa iyong buhay at sa lipunan sa kabuuan. Ano ang mga aral na iyong natutunan? \n• Konklusyon: Magbigay ng buod ng iyong mga natutunan at kung paano mo ito magagamit sa hinaharap. \n3. Ang reflection ay dapat mayroong 300-500 na salita.',
                tableData: [
                    {
                        column1: "Kategorya ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pag-unlad (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang nilalaman ng reflection. Malinaw na nailahad ang mga positibo at negatibong saloobin, pati na rin ang pagsusuri.",
                        column3: "May sapat na detalye ang nilalaman, ngunit may ilang bahagi na maaaring mas mapalalim o mas maging malinaw.",
                        column4: "Kulang ang detalye o may ilang aspeto ng saloobin na hindi ganap na nailahad.",
                        column5: "Kakulangan sa nilalaman; maraming aspeto ang hindi nabanggit o hindi malinaw ang mga saloobin."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Mahusay ang pagkakaayos ng impormasyon; madaling sundan at nauugnay ang mga bahagi sa isa’t isa.",
                        column3: "Organisado ang reflection, ngunit may ilang bahagi na maaaring higit pang ayusin para sa mas magandang daloy ng impormasyon.",
                        column4: "Medyo magulo ang pagkakaayos; hindi ganap na malinaw ang pagkakasunod-sunod ng mga ideya.",
                        column5: "Hindi organisado; mahirap sundan ang daloy ng impormasyon at hindi malinaw ang ugnayan ng mga bahagi."
                    },
                    {
                        column1: "Wika at Estilo",
                        column2: "Mahusay ang paggamit ng wika; angkop ang istilo sa paksang tinatalakay, at walang grammatical errors.",
                        column3: "Ang paggamit ng wika ay maayos, ngunit may ilang maliit na pagkakamali sa grammar o pagbabaybay.",
                        column4: "May mga pagkakamali sa grammar o pagbabaybay na nakakaapekto sa kalinawan ng mensahe.",
                        column5: "Maraming pagkakamali sa grammar o pagbabaybay; mahirap intidihin ang mensahe."
                    },
                ],
            },
            "Graphic Organizer": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: '1. Gumawa ng isang Graphic Organizer na nagpapakita ng mga positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon: \n• Pang-ekonomiya \n• Pangteknolohikal \n• Panlipunan \n• Sosyo-kultural \n2. Ang graphic organizer ay dapat malinaw na nagpapakita ng ugnayan ng bawat aspeto, kasama ang mga positibo at negatibong epekto ng globalisasyon sa bawat isa. \n3. Gumamit ng tamang pagkakategorya, hugis, kulay, at linya upang mas madaling maipahayag ang impormasyon.',
                tableData: [
                    {
                        column1: "Kategorya ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pag-unlad (1)"
                    },
                    {
                        column1: "Klaridad ng Impormasyon",
                        column2: "Napakalinaw ng impormasyon; naipakita nang buo ang positibo at negatibong epekto ng globalisasyon.",
                        column3: "Malinaw ang impormasyon ngunit may ilang bahagi na maaaring palawakin o linawin.",
                        column4: "Hindi ganap na malinaw; kulang ang pagpapakita ng mga positibo at negatibong epekto.",
                        column5: "Magulo at hindi malinaw ang impormasyon; hindi naipakita ang mga epekto ng globalisasyon."
                    },
                    {
                        column1: "Organisasyon ng Nilalaman",
                        column2: "Lubos na organisado at lohikal ang daloy ng mga ideya sa bawat aspeto.",
                        column3: "Maayos ang organisasyon, ngunit may ilang bahagi na hindi ganap na malinaw.",
                        column4: "Hindi masyadong maayos; may mga bahagi na mahirap sundan at hindi lohikal ang daloy.",
                        column5: "Hindi organisado at mahirap intindihin; walang lohikal na daloy ng mga ideya."
                    },
                    {
                        column1: "Paggamit ng Hugis, Kulay, Linya",
                        column2: "Malikhaing at angkop ang paggamit ng hugis, kulay, at linya upang suportahan ang ugnayan ng impormasyon.",
                        column3: "Angkop ang paggamit ngunit maaaring pagbutihin ang ilang bahagi ng visual elements.",
                        column4: "Limitado o hindi wasto ang paggamit ng visual elements, na nakakaapekto sa pagpapahayag.",
                        column5: "Hindi angkop o sapat ang paggamit ng visual elements; hindi nakatulong sa presentasyon."
                    },
                    {
                        column1: "Kabuuang Presentasyon",
                        column2: "Mahusay at propesyonal ang pagkakagawa ng graphic organizer; kaakit-akit at malinaw ang lahat ng bahagi.",
                        column3: "Maayos ang pagkakagawa; malinaw ngunit may ilang detalye na maaaring mapabuti.",
                        column4: "Hindi ganap na maayos ang kabuuan; may mga detalye na hindi naipresenta nang mabuti.",
                        column5: "Kulang sa detalye at hindi maayos ang pagkakagawa; hindi kaakit-akit o malinaw."
                    },
                ],
            },
            "Picture Analysis": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: '1. Maghanap sa internet ng isang larawan na nagpapakita ng mga positibo at negatibong epekto ng globalisasyon. \n2.  I-analyze ang larawan sa pamamagitan ng pagtalakay sa mga aspeto ng globalisasyon na nakikita dito, at ipaliwanag ang mga saloobin hinggil dito. \n3. Isulat ang iyong analisis sa isang maikling sanaysay na naglalarawan ng iyong obserbasyon at pananaw \n4. Isulat ang sanggunian sa huling parte.',
                tableData: [
                    {
                        column1: "Kategorya ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pag-unlad (1)"
                    },
                    {
                        column1: "Klaridad ng Analisis",
                        column2: "Napakalinaw at detalyado ang analisis; lahat ng aspeto ng larawan ay naipaliwanag nang mahusay.",
                        column3: "Malinaw ang analisis ngunit may ilang bahagi na hindi ganap na napalawig.",
                        column4: "Kulang ang detalye sa analisis; hindi lahat ng aspeto ng larawan ay naipaliwanag nang maayos.",
                        column5: "Magulo at hindi malinaw ang analisis; hindi sapat ang pagtalakay sa mga aspeto ng larawan."
                    },
                    {
                        column1: "Kaugnayan sa Globalisasyon",
                        column2: "Malinaw na naipakita ang kaugnayan ng larawan sa mga positibo at negatibong epekto ng globalisasyon.",
                        column3: "Naipakita ang kaugnayan ngunit may ilang aspeto na hindi ganap na napalawig.",
                        column4: "May kaunting kaugnayan sa globalisasyon, ngunit hindi ito ganap na naipaliwanag.",
                        column5: "Walang malinaw na kaugnayan ang analisis sa mga epekto ng globalisasyon."
                    },
                    {
                        column1: "Organisasyon ng Sanaysay",
                        column2: "Lubos na organisado ang sanaysay; may malinaw na simula, gitna, at wakas.",
                        column3: "Maayos ang organisasyon, ngunit may ilang bahagi na hindi ganap na malinaw.",
                        column4: "Hindi masyadong maayos ang daloy; may mga bahagi na mahirap sundan.",
                        column5: "Hindi organisado ang sanaysay; walang lohikal na daloy ng mga ideya."
                    },
                    {
                        column1: "Paggamit ng Wika at Estilo",
                        column2: "Mahusay ang paggamit ng wika; kaakit-akit at angkop ang istilo sa pagsusuri.",
                        column3: "Maayos ang paggamit ng wika, ngunit may ilang pagkakamali o hindi angkop na istilo.",
                        column4: "Limitado ang paggamit ng wika; maraming pagkakamali o hindi angkop na istilo.",
                        column5: "Magulo ang paggamit ng wika; maraming pagkakamali at mahirap intindihin."
                    },
                ],
            },
            "Article Analysis": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: '1.  Maghanap sa internet ng isang artikulo na tumatalakay sa mga positibo at negatibong epekto ng globalisasyon. \n2. I-analyze ang artikulo sa pamamagitan ng pagtukoy sa mga pangunahing ideya, argumento, at ebidensya na ginamit ng may-akda. \n3. Isulat ang iyong analisis sa isang maikling sanaysay na naglalarawan ng iyong obserbasyon at pananaw hinggil sa artikulo. \n4. Isulat ang sanggunian sa huling parte.',
                tableData: [
                    {
                        column1: "Kategorya ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pag-unlad (1)"
                    },
                    {
                        column1: "Klaridad ng Analisis",
                        column2: "Napakalinaw at detalyado ang analisis; lahat ng pangunahing ideya at argumento ay naipaliwanag nang mahusay.",
                        column3: "Malinaw ang analisis ngunit may ilang bahagi na hindi ganap na napalawig.",
                        column4: "Kulang ang detalye sa analisis; hindi lahat ng pangunahing ideya at argumento ay naipaliwanag nang maayos.",
                        column5: "Magulo at hindi malinaw ang analisis; hindi sapat ang pagtalakay sa mga ideya ng artikulo."
                    },
                    {
                        column1: "Kaugnayan sa Globalisasyon",
                        column2: "Malinaw na naipakita ang kaugnayan ng artikulo sa mga positibo at negatibong epekto ng globalisasyon.",
                        column3: "Naipakita ang kaugnayan ngunit may ilang aspeto na hindi ganap na napalawig.",
                        column4: "May kaunting kaugnayan sa globalisasyon, ngunit hindi ito ganap na naipaliwanag.",
                        column5: "Walang malinaw na kaugnayan ang analisis sa mga epekto ng globalisasyon."
                    },
                    {
                        column1: "Organisasyon ng Sanaysay",
                        column2: "Lubos na organisado ang sanaysay; may malinaw na simula, gitna, at wakas.",
                        column3: "Maayos ang organisasyon, ngunit may ilang bahagi na hindi ganap na malinaw.",
                        column4: "Hindi masyadong maayos ang daloy; may mga bahagi na mahirap sundan.",
                        column5: "Hindi organisado ang sanaysay; walang lohikal na daloy ng mga ideya."
                    },
                    {
                        column1: "Paggamit ng Wika at Estilo",
                        column2: "Mahusay ang paggamit ng wika; kaakit-akit at angkop ang istilo sa pagsusuri.",
                        column3: "Maayos ang paggamit ng wika, ngunit may ilang pagkakamali o hindi angkop na istilo.",
                        column4: "Limitado ang paggamit ng wika; maraming pagkakamali o hindi angkop na istilo.",
                        column5: "Magulo ang paggamit ng wika; maraming pagkakamali at mahirap intindihin."
                    },
                ],
            },
        },
    },
];


function FormativeAssessmentInput() {
    const location = useLocation();
    const { assessment, isEditing, aralinTitle, activityName, aralinId } = location.state || {}; // Get the isEditing flag


    // Find the selected aralin and activity details
    const selectedAralin = aralinData.find((aralin) => aralin.title === aralinTitle);
    const activityDetails = selectedAralin?.activities[activityName];


    const [isMobile, setIsMobile] = useState(false);
    const [userUID, setUserUID] = useState(null); // State to store the current user's UID

    // Initialize your form states with assessment data if available
    const [topicTitle, setTopicTitle] = React.useState(assessment ? assessment.title : "");
    const [objectives, setObjectives] = React.useState(assessment ? assessment.objectives : "");
    const [instructions, setInstructions] = React.useState(assessment ? assessment.instructions : "");
    const [format, setFormat] = React.useState(assessment ? assessment.format : ""); // New state for Format
    const [tableData, setTableData] = useState(
        assessment ? assessment.tableData : activityDetails?.tableData || []
    );

    const [isEditableObjectives, setIsEditableObjectives] = useState(false);
    const [isEditableInstructions, setIsEditableInstructions] = useState(false);
    const [isEditableFormat, setIsEditableFormat] = useState(false);
    const [divSize, setDivSize] = useState({ width: 0, height: 0 });
    const navigate = useNavigate();

    const COLUMN_ORDER = ["column1", "column2", "column3", "column4", "column5"];
    const TWO_COLUMN_ORDER = ["column1", "column2"]; // Define order for two-column tables


    useEffect(() => {
        if (activityDetails) {
            setObjectives(activityDetails.objectives);
            setInstructions(activityDetails.instructions);
            setFormat(activityDetails.format || ""); // Set format if available
            setTableData(activityDetails.tableData);
        }
    }, [activityDetails]);

    // Responsive UI
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 600px)").matches);
        };

        handleResize(); // Initialize state
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (selectedAralin && activityDetails) {
            setObjectives(activityDetails.objectives || "No objectives found.");
            setInstructions(activityDetails.instructions || "No instructions found.");
        }
    }, [selectedAralin, activityDetails]);


    useEffect(() => {
        const orderedTableData = (data) => {
            const isTwoColumnTable = data[0] && Object.keys(data[0]).length === 2;
            const order = isTwoColumnTable ? TWO_COLUMN_ORDER : COLUMN_ORDER;

            return data.map((row) => {
                const orderedRow = {};
                order.forEach((col) => {
                    orderedRow[col] = row[col] || "";
                });
                return orderedRow;
            });
        };

        if (isEditing && assessment) {
            // Load existing assessment data in edit mode
            setTopicTitle(assessment.title || "");
            setObjectives(assessment.objectives || "");
            setInstructions(assessment.instructions || "");
            setFormat(assessment.format || ""); // Load format if available
            setTableData(assessment.tableData || []);
        } else if (selectedAralin) {
            setTopicTitle(selectedAralin.title);
            setObjectives(activityDetails?.objectives || "");
            setInstructions(activityDetails?.instructions || "");
            setFormat(activityDetails?.format || ""); // Load format only if available in aralinData
            setTableData(activityDetails?.tableData || []);
        }
    }, [isEditing, assessment, selectedAralin, activityDetails]);



    function throttle(func, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = Date.now();
            if (now - lastCall >= delay) {
                lastCall = now;
                func(...args);
            }
        };
    }

    // ResizeObserver with throttling
    useEffect(() => {
        const divElem = document.querySelector("#resize-observed-div");
        const throttledSetDivSize = throttle((entry) => {
            setDivSize({
                width: entry.contentRect.width,
                height: entry.contentRect.height,
            });
            console.log("Div resized:", entry.contentRect);
        }, 100); // Adjust throttle delay as needed

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                throttledSetDivSize(entry);
            }
        });

        if (divElem) resizeObserver.observe(divElem);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);



    const handleAddRow = () => {
        const newRow = {};
        Object.keys(tableData[0] || { column1: "", column2: "" }).forEach(col => {
            newRow[col] = ""; // Initialize each cell with an empty string
        });
        setTableData([...tableData, newRow]);
    };

    const handleRemoveRow = (index) => {
        if (tableData.length > 1) {
            setTableData(tableData.filter((_, i) => i !== index));
        }
    };

    const handleCellChange = (rowIndex, colName, value) => {
        setTableData(prevData =>
            prevData.map((row, idx) =>
                idx === rowIndex ? { ...row, [colName]: value } : row
            )
        );
    };


    // Check for authenticated user and get UID
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserUID(user.uid); // Set UID for the logged-in user
            } else {
                console.warn("User is not authenticated");
            }
        });
        return () => unsubscribe(); // Cleanup listener on component unmount
    }, []);

    const handleSaveOrUpdate = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to save this formative assessment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        });

        if (result.isConfirmed) {
            if (!userUID) {
                console.error("User UID not found. Please log in.");
                return;
            }

            try {
                const activityName = location.state?.activityName || "Default Activity Name";
                const dataToSave = {
                    title: topicTitle,
                    objectives,
                    instructions,
                    tableData,
                    updatedAt: serverTimestamp(),
                    createdBy: userUID,
                    activityName
                };

                if (format) dataToSave.format = format; // Only add format if it's available

                if (isEditing && assessment?.id) {
                    const docRef = doc(db, "formativeAssessments", assessment.id);
                    await updateDoc(docRef, dataToSave);
                } else {
                    dataToSave.createdAt = serverTimestamp();
                    await addDoc(collection(db, "formativeAssessments"), dataToSave);
                }
                Swal.fire('Saved!', 'Your formative assessment has been saved.', 'success');
                navigate("/assessment-records/formative-assessment");
            } catch (error) {
                console.error("Error saving formative assessment:", error);
                Swal.fire('Error!', 'Failed to save formative assessment.', 'error');
            }
        }
    };

    // Dynamic Table Rendering
    const columns = COLUMN_ORDER.filter((col) => tableData[0]?.hasOwnProperty(col));



    return (
        <Box id="resize-observed-div" sx={{ mt: isMobile ? 12 : 14, px: isMobile ? 2 : 6 }}>
            <Typography variant="h4" fontWeight="bold" fontFamily="Poppins" textAlign="center" mb={2}>
                FORMATIVE ASSESSMENT
            </Typography>
            <Typography variant="h5" fontFamily="Poppins" fontWeight="medium" textAlign="center" mb={4}>
                (Aralin {aralinId} - {activityName})
            </Typography>

            <Grid container spacing={3} justifyContent="center">
                {/* TITLE OF TOPIC (PAKSA) */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            TITLE OF TOPIC (PAKSA):
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={topicTitle}
                            disabled
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: !isEditableObjectives ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                },
                                "& .MuiOutlinedInput-root.Mui-disabled": {
                                    backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                },
                                "& .MuiOutlinedInput-root fieldset": {
                                    borderColor: "black",
                                    borderWidth: "2px",
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                {/* TYPE OF TASK */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            TYPE OF TASK:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h5" fontFamily="Poppins" fontWeight="bold" color="#870400">
                            {activityName}
                        </Typography>
                    </Grid>
                </Grid>
                {/* Objectives Section */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            OBJECTIVES (LAYUNIN):
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ position: "relative" }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                value={objectives}
                                onChange={(e) => setObjectives(e.target.value)}
                                disabled={!isEditableObjectives}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: !isEditableObjectives ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled": {
                                        backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                    },
                                    "& .MuiOutlinedInput-root fieldset": {
                                        borderColor: "black",
                                        borderWidth: "2px",
                                    },
                                }}
                            />
                            <Button
                                variant="text"
                                sx={{
                                    position: "absolute",
                                    bottom: 8,
                                    right: 8,
                                    textTransform: "none",
                                    textDecoration: "underline", // Underline the button text
                                    "&:hover": {
                                        textDecoration: "underline", // Maintain underline on hover
                                    },
                                }}
                                onClick={() => setIsEditableObjectives(!isEditableObjectives)}
                            >
                                {isEditableObjectives ? "Save" : "Edit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {/* Instructions Section */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            INSTRUCTIONS (PANUTO):
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ position: "relative" }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                                disabled={!isEditableInstructions}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: !isEditableInstructions ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled": {
                                        backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                    },
                                    "& .MuiOutlinedInput-root fieldset": {
                                        borderColor: "black",
                                        borderWidth: "2px",
                                    },
                                }}
                            />
                            <Button
                                variant="text"
                                sx={{
                                    position: "absolute",
                                    bottom: 8,
                                    right: 8,
                                    textTransform: "none",
                                    textDecoration: "underline", // Underline the button text
                                    "&:hover": {
                                        textDecoration: "underline", // Maintain underline on hover
                                    },
                                }}
                                onClick={() => setIsEditableInstructions(!isEditableInstructions)}
                            >
                                {isEditableInstructions ? "Save" : "Edit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {format && (
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                                FORMAT/PORMAT:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ position: "relative" }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={format}
                                    onChange={(e) => setFormat(e.target.value)}
                                    disabled={!isEditableFormat}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: !isEditableFormat ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                        },
                                        "& .MuiOutlinedInput-root.Mui-disabled": {
                                            backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                        },
                                        "& .MuiOutlinedInput-root fieldset": {
                                            borderColor: "black",
                                            borderWidth: "2px",
                                        },
                                    }}
                                />
                                <Button
                                    variant="text"
                                    sx={{
                                        position: "absolute",
                                        bottom: 8,
                                        right: 8,
                                        textTransform: "none",
                                        textDecoration: "underline", // Underline the button text
                                        "&:hover": {
                                            textDecoration: "underline", // Maintain underline on hover
                                        },
                                    }}
                                    onClick={() => setIsEditableFormat(!isEditableFormat)}
                                >
                                    {isEditableFormat ? "Save" : "Edit"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Grid>


            <Grid container spacing={3} justifyContent="center">
                {/* Rubrics */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" fontWeight="bold" sx={{ mt: 5 }}>Rubrics</Typography>
                    </Grid>
                </Grid>

                {/* Dynamic Table */}
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ mt: 4, width: "fit-content", mx: "auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((col, idx) => (
                                        <TableCell key={idx} align="center" sx={{ fontWeight: "bold", fontFamily: "Poppins" }}>{col.toUpperCase()}</TableCell>
                                    ))}
                                    <TableCell align="center" sx={{ fontWeight: "bold", fontFamily: "Poppins" }}>ACTIONS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {columns.map((col, colIndex) => (
                                            <TableCell key={colIndex}>
                                                <TextField
                                                    value={row[col]}
                                                    onChange={(e) => handleCellChange(rowIndex, col, e.target.value)}
                                                    //multiline
                                                    rows={2}
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <IconButton onClick={handleAddRow} sx={{ color: 'blue' }}>
                                                    <Add />
                                                </IconButton>
                                                <IconButton onClick={() => handleRemoveRow(rowIndex)} sx={{ color: 'red' }}>
                                                    <Remove />
                                                </IconButton>
                                            </Box>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                    <Button variant="contained" sx={{ backgroundColor: "#870400" }} onClick={handleSaveOrUpdate}>
                        {isEditing ? "Update" : "Save"}  {/* Change button text based on edit mode */}
                    </Button>
                </Box>

            </Grid>
        </Box >
    );
}

export default FormativeAssessmentInput;
