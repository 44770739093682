import React from "react";
import { Box, Typography, Container, Divider, useMediaQuery, useTheme } from '@mui/material';

function About() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Queries mobile screens

    return (
        <Container maxWidth="md" sx={{ mt: 14 }}>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1" fontWeight="bold" fontFamily="Poppins">
                    ABOUT
                </Typography>
            </Box>
            <Divider sx={{ mb: 3, backgroundColor: '#FF9D01', height: '3px' }} />
            <Box>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        textAlign: 'justify',
                        fontSize: isMobile ? '1.1rem' : '1.6rem', // Adjust font size based on screen size
                        lineHeight: 1.7
                    }}
                >
                    <Box component="span" fontStyle="italic">Kaiba</Box> is an innovative project-based assessment tool that helps teachers provide useful project-based assessments. It includes a variety of activities with comprehensive rubrics, all of which correspond well with the lesson's intended learning outcomes. By focusing on practical, outcome-driven tasks, <Box component="span" fontStyle="italic">Kaiba</Box> supports teachers in encouraging deeper learning and crucial skills while also ensuring evaluations are relevant, structured, and meaningful for students' educational journeys.
                </Typography>
            </Box>
        </Container>
    );
}

export default About;
