import React, { useState, useEffect } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Edit, Download, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { collection, getDocs, deleteDoc, doc, query, where, orderBy } from "firebase/firestore";
import { db } from "../config/firebase";
import { getAuth } from "firebase/auth";
import Swal from 'sweetalert2';
import { format } from "date-fns";

function SummativeAssessmentRecords() {
    const navigate = useNavigate();
    const [assessments, setAssessments] = useState([]);

    // Fetch assessments created by the current user from Firestore
    useEffect(() => {
        const fetchAssessments = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const assessmentsRef = collection(db, "summativeAssessments");
                const q = query(assessmentsRef, where("createdBy", "==", currentUser.uid), orderBy("createdAt", "asc"));
                const querySnapshot = await getDocs(q);
                const assessmentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAssessments(assessmentsData);
            }
        };
        fetchAssessments();
    }, []);

    const handleEdit = (assessment) => {
        navigate(`/create-assessment/summative-assessment/input`, {
            state: { assessment, isEditing: true, activityName: assessment.activityName }
        });
    };



    const handleDelete = (assessmentId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDoc(doc(db, "summativeAssessments", assessmentId));
                setAssessments(assessments.filter(a => a.id !== assessmentId));
                Swal.fire("Deleted!", "Your assessment has been deleted.", "success");
            }
        });
    };

    const handleDownload = async (assessment) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to download this PDF file?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, download it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const mainPdfBytes = await fetch("/resources/kaiba_pdf_template.pdf").then(res => res.arrayBuffer());
                    const nextPagePdfBytes = await fetch("/resources/kaiba_for_next_page.pdf").then(res => res.arrayBuffer());

                    const mainPdfDoc = await PDFDocument.load(mainPdfBytes);
                    const nextPagePdfDoc = await PDFDocument.load(nextPagePdfBytes);

                    const pdfDoc = await PDFDocument.create();
                    const [mainTemplatePage] = await pdfDoc.copyPages(mainPdfDoc, [0]);
                    let page = pdfDoc.addPage(mainTemplatePage);

                    const { width, height } = page.getSize();
                    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
                    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
                    const italicFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);

                    const fontSize = 11;
                    let y = 750;

                    const splitTextIntoLines = (text, maxWidth) => {
                        const lines = [];
                        const paragraphs = text.split('\n');
                        paragraphs.forEach(paragraph => {
                            const words = paragraph.split(' ');
                            let currentLine = '';
                            for (let word of words) {
                                const lineWidth = font.widthOfTextAtSize(`${currentLine}${currentLine ? ' ' : ''}${word}`, fontSize);
                                if (lineWidth < maxWidth) {
                                    currentLine += (currentLine ? ' ' : '') + word;
                                } else {
                                    if (currentLine) lines.push(currentLine);
                                    currentLine = word;
                                }
                            }
                            if (currentLine) lines.push(currentLine);
                        });
                        return lines;
                    };

                    const drawTextWrapped = (text, x, y, maxWidth, font) => {
                        const lines = splitTextIntoLines(text, maxWidth);
                        lines.forEach((line) => {
                            page.drawText(line, { x, y, size: fontSize, font, color: rgb(0, 0, 0) });
                            y -= fontSize + 2;
                        });
                        return y;
                    };

                    // Static fields for the assessment details
                    y = drawTextWrapped(`Paksa: ${assessment.title}`, 50, y, width - 100, fontBold) - 20;
                    page.drawText("Layunin:", { x: 50, y, size: fontSize, font: fontBold, color: rgb(0, 0, 0) });
                    y = drawTextWrapped(assessment.objectives, 70, y - 20, width - 120, font) - 20;
                    y = drawTextWrapped(`Gawain: ${assessment.activityName}`, 50, y, width - 100, fontBold) - 20;
                    page.drawText("Panuto:", { x: 50, y, size: fontSize, font: fontBold, color: rgb(0, 0, 0) });
                    y = drawTextWrapped(assessment.instructions, 70, y - 20, width - 120, font) - 20;
                    if (assessment.format) {
                        page.drawText("Pormat:", { x: 50, y, size: fontSize, font: fontBold, color: rgb(0, 0, 0) });
                        y = drawTextWrapped(assessment.format, 70, y - 20, width - 120, font) - 20;
                    }
                    page.drawText("Rubriks", { x: 50, y, size: fontSize, font: fontBold, color: rgb(0, 0, 0) });

                    // Table Rendering
                    const tableData = assessment.tableData || [];
                    const columnNames = Object.keys(tableData[0] || {}).sort(); // Ensure consistent column order
                    const columnCount = columnNames.length;
                    const cellWidth = (width - 100) / columnCount;
                    y -= 20;

                    for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
                        const row = tableData[rowIndex];
                        const cellHeights = columnNames.map((col) => {
                            const cell = row[col];
                            const cellLines = splitTextIntoLines(cell, cellWidth - 10);
                            return cellLines.length * (fontSize + 2) + 5;
                        });
                        const rowHeight = Math.max(...cellHeights);

                        if (y - rowHeight < 50) {
                            const [nextTemplatePage] = await pdfDoc.copyPages(nextPagePdfDoc, [0]);
                            page = pdfDoc.addPage(nextTemplatePage);
                            y = height - 50;
                        }

                        const initialY = y;
                        columnNames.forEach((col, colIndex) => {
                            const cell = row[col];
                            const x = 50 + colIndex * cellWidth;
                            drawTextWrapped(cell, x + 5, initialY - 12, cellWidth - 10, font);
                            page.drawRectangle({
                                x,
                                y: initialY - rowHeight,
                                width: cellWidth,
                                height: rowHeight,
                                borderColor: rgb(0, 0, 0),
                                borderWidth: 1,
                            });
                        });
                        y -= rowHeight;
                    }

                    // ADDITIONAL CONTENT: Explanatory text and additional table
                    // Calculate the remaining space on the current page
                    const remainingSpace = y - 50;  // The 50 is the minimum space before we consider a new page

                    // Check if the remaining space is enough for the additional content
                    const additionalContentHeight = 200; // Estimate the height required for the additional content (explanatory text + table)
                    if (remainingSpace < additionalContentHeight) {
                        // If there isn't enough space, create a new page
                        const [nextTemplatePage] = await pdfDoc.copyPages(nextPagePdfDoc, [0]);
                        page = pdfDoc.addPage(nextTemplatePage);
                        y = height - 50; // Reset y to start at the top of the new page
                    }

                    // ADDITIONAL CONTENT: Explanatory text and additional table
                    y -= 20;

                    y = drawTextWrapped(
                        "Maaaring gamitin ng mga guro ang sistemang ito ng transmutasyon upang i-convert ang mga score sa rubric patungo sa porsyento na mas tumpak na nagpapakita ng iba’t ibang antas ng pagganap ng mga mag-aaral. Sa pamamagitan ng pag-aangkop ng bawat score sa rubric (4, 3, 2, 1) sa isang partikular na hanay ng porsyento, mas nagiging malinaw ang pagkakaiba ng bawat antas ng pagganap.",
                        50,
                        y,
                        width - 100,
                        italicFont
                    );

                    y -= 8;
                    y = drawTextWrapped("Transmuted Score = (Raw Score/Maximum Raw Score) × Maximum Transmuted Score", 50, y, width - 100, italicFont);

                    y -= 3;
                    y = drawTextWrapped("Transmuted Score = (Raw Score/16) × 100", 50, y, width - 100, italicFont);

                    // Render additional table with specific content
                    y -= 15;
                    page.drawText("Puntos", { x: 50, y, size: fontSize, font: fontBold, color: rgb(0, 0, 0) });
                    page.drawText("Transmuted Score (Percentage Range)", { x: 250, y, size: fontSize, font: fontBold, color: rgb(0, 0, 0) });
                    y -= 15;

                    const rows = [
                        { puntos: "4 (Napakahusay)", range: "100% - 90%" },
                        { puntos: "3 (Mahusay)", range: "89% - 75%" },
                        { puntos: "2 (Katamtaman)", range: "74% - 60%" },
                        { puntos: "1 (Kailangan ng Pagsasanay)", range: "59% - 0%" }
                    ];

                    rows.forEach(row => {
                        page.drawText(row.puntos, { x: 50, y, size: fontSize, font, color: rgb(0, 0, 0) });
                        page.drawText(row.range, { x: 250, y, size: fontSize, font, color: rgb(0, 0, 0) });
                        y -= fontSize + 5;
                    });

                    // Final italicized closing text
                    y -= 20;
                    y = drawTextWrapped(
                        "Ang paraang ito ay sumusuporta sa patas at pare-parehong pagbibigay ng grado, na nagbibigay sa mga mag-aaral at magulang ng mas komprehensibong pag-unawa sa akademikong pag-unlad sa loob ng 100-point grading scale.",
                        50,
                        y,
                        width - 100,
                        italicFont
                    );

                    const pdfBytes = await pdfDoc.save();
                    const blob = new Blob([pdfBytes], { type: "application/pdf" });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${assessment.title}.pdf`;
                    a.click();
                    URL.revokeObjectURL(url);
                } catch (error) {
                    console.error("Error during PDF generation:", error);
                }
            }
        });
    };





    return (
        <Box sx={{ mt: 4, px: 6 }}>
            <Typography variant="h4" fontWeight="bold" textAlign="center" mb={2} sx={{ mt: 14 }}>
                History of Summative Assessments
            </Typography>
            <TableContainer component={Paper} sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', boxShadow: 3, mb: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Assessment Title</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Date Created</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assessments.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                        No summative assessments yet
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            assessments.map((assessment, index) => (
                                <TableRow key={assessment.id}>
                                    <TableCell align="center">{`Summative Assessment #${index + 1}`}</TableCell>
                                    <TableCell align="center">{format(assessment.createdAt.toDate(), 'MMMM dd, yyyy')}</TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={() => handleEdit(assessment)} color="primary">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDownload(assessment)} color="secondary">
                                            <Download />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(assessment.id)} color="error">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default SummativeAssessmentRecords;