import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, Divider } from '@mui/material';
import banner from '../res/banner.png';
import projectImage from '../res/project_based.png'; // Import your image here

function Home() {
    const [isMobile, setIsMobile] = useState(false);

    // Check if the device is mobile using matchMedia
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia('(max-width: 600px)').matches);
        };

        // Initial check and add event listener for window resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box>
            {/* Existing Banner Code */}
            <Box
                sx={{
                    position: 'relative', // Change to relative to allow scrolling
                    width: isMobile ? '105%' : '101.3%',
                    overflow: 'hidden',
                    left: -10,
                    top: 59,
                }}
            >
                <Box
                    component="img"
                    src={banner}
                    alt="Banner"
                    sx={{
                        width: '100%',
                        height: 'auto', // Allow height to be auto for responsiveness
                        objectFit: 'cover',
                        display: 'block',
                    }}
                />
            </Box>

            {/* New Content Section */}
            <Grid container spacing={2} sx={{ marginTop: isMobile ? '60px' : '50px' }}>
                {/* Text Section */}
                <Grid item xs={12} md={6}>
                    <Box padding={2} sx={{ mt: isMobile ? 0 : 5, ml: isMobile ? 0 : 3 }}>
                        <Typography variant="h5" component="div" fontWeight="bold" fontFamily='Poppins' textAlign='center' sx={{ mb: isMobile ? 0 : 3 }}>
                            WHAT IS PROJECT-BASED ASSESSMENT?
                        </Typography>
                        <Typography variant="body1" paragraph component="div" sx={{ marginTop: '10px', textAlign: 'justify' }}>
                            Project-based assessments (PBAs) are the means through which teachers measure student knowledge gained via project-based learning (PBL) — a student-centered teaching approach that uses engaging, real-world applications and hands-on learning to help students build knowledge while strengthening critical thinking and problem-solving skills.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }} >
                            In classrooms that use PBL, students often work together to answer curriculum-relevant questions and solve challenges, preparing them to become adept communicators and collaborators in their future lives and careers. Instead of end-of-unit tests, they are assessed through group or independent projects.
                        </Typography>

                    </Box>
                </Grid>

                {/* Image Section */}
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        src={projectImage} // Update with your actual image path
                        alt="Project-based assessment"
                        sx={{
                            width: '60%', // Ensure it doesn't exceed the container width
                            height: 'auto', // Maintain aspect ratio
                            maxWidth: isMobile ? '100%' : '500px', // Limit max width on larger screens
                            ml: isMobile ? 8 : 17,
                            mt: isMobile ? -3 : 2,
                        }}
                    />
                </Grid>
            </Grid>

            {/* Divider after Content */}
            <Divider sx={{ margin: '20px 0', backgroundColor: '#FF9D01', height: '3px' }} />

            {/* Importance of Project-Based Assessments Section */}
            <Box padding={2} sx={{ mt: 2 }}>
                <Typography variant="h5" fontWeight="bold" fontFamily="Poppins" textAlign="center" sx={{ mb: 2 }}>
                    Importance of Project-Based Assessments
                </Typography>
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                    In the 21st century education, communication, collaboration, creativity, and critical thinking are commonly referred to as the "4Cs" of 21st-century education, with the goal of preparing students for a fast-changing world. These skills empower students not only cognitively, but also socially and professionally, making them more adaptive, educated, and proactive members of society. Education models such as Education 4.0 stress the integration of these skills into teaching practices in order to create a holistic and future-ready learning environment.
                </Typography>
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                    In fostering these skills, project-based assessments play a crucial role in developing crucial 21st-century skills by immersing students in meaningful, hands-on learning experiences that mirror real-world issues. Project-based projects, unlike traditional assessments, help students build communication skills by requiring them to explain findings, articulate ideas, and communicate with a variety of audiences via presentations or written reports. Collaboration is also important since many projects demand teamwork, which teaches students how to work effectively with others and value various viewpoints of perspective. Furthermore, the open-ended nature of projects encourages creativity, allowing students to think innovatively and approach tasks in different ways. Finally, project-based assessments promote critical thinking as students analyze problems, find solutions, and make educated conclusions. Overall, these assessments offer a dynamic, interactive learning environment in which students can apply their knowledge and develop skills that are essential for success in the modern world.
                </Typography>
            </Box>
        </Box>
    );
}

export default Home;
